import { ThemeItem, Zone } from '../models';

const theme80s: ThemeItem = {
  color: '#1aed1f',
  mainFont: '"Perfect DOS VGA 437 Win"',
  numbersFont: '"Perfect DOS VGA 437 Win"'
}

const theme90s: ThemeItem = {
  color: 'black',
  mainFont: '"Oracle Regular"',
  numbersFont: 'PokemonGB'
}

const theme20s: ThemeItem = {
  color: 'black',
  mainFont: '"Oracle Regular"',
  numbersFont: 'Georgia'
}
const themeConfig = {
  [Zone.ZONE_90s]: theme90s,
  [Zone.ZONE_80s]: theme80s,
  [Zone.ZONE_20s]: theme20s,
}

export {
  theme90s,
  theme80s,
  theme20s,
  themeConfig
}