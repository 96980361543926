import httpClient, { ResponseInt } from '../httpClient';
import { UserID, UserInfo } from '../models/UserModel';

export interface UserRepository {
  getUserInfo(userId: number): Promise<ResponseInt<UserInfo>>;
  addUser(): Promise<UserID>;
  updateUser(): Promise<void>;
}

export class UserRepositoryImpl implements UserRepository {
  async getUserInfo(userId: number) {
    return await httpClient.get<UserInfo>(`/user/${userId}`);
  }
  async addUser(): Promise<UserID> {
    return await httpClient.post('/user');
  }
  async updateUser(): Promise<void> {
    return await httpClient.put('/user');
  }
}