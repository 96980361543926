import React from 'react';
import styled from 'styled-components';

const IconTitleStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:#a32417;}
  .cls-2{fill:#233b3f;}
  .cls-3{fill:#d8aa44;}
  .cls-4{fill:#c74634;}
  .cls-5{fill:#3e686c;}
  .cls-6{fill:#f0cc71;}
`

export default function IconTitle() {
  return (
      <IconTitleStyled
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1013.57 295.71"
      >
        <polygon
          className="cls-1"
          points="960.22 295.71 1013.57 295.71 1013.57 242.36 1013.57 189.01 960.22 189.01 960.22 242.36 960.22 295.71"
        />
        <polygon
          className="cls-1"
          points="960.22 28.99 960.22 82.31 960.22 135.66 1013.57 135.66 1013.57 82.31 1013.57 28.99 960.22 28.99"
        />
        <rect
          className="cls-1"
          x="906.87"
          y="135.66"
          width="53.35"
          height="53.35"
        />
        <polygon
          className="cls-1"
          points="853.52 242.36 853.52 295.71 906.87 295.71 906.87 242.36 906.87 189.01 853.52 189.01 853.52 242.36"
        />
        <polygon
          className="cls-1"
          points="906.87 28.99 853.52 28.99 853.52 82.31 853.52 135.66 906.87 135.66 906.87 82.31 906.87 28.99"
        />
        <path
          className="cls-2"
          d="M746.85,29H640.16V295.71h160V29Zm0,106.67v106.7H693.5v-160h53.35Z"
        />
        <polygon
          className="cls-1"
          points="533.46 242.36 480.11 242.36 480.11 189.01 480.11 135.66 480.11 82.31 480.11 28.99 426.76 28.99 426.76 82.31 426.76 135.66 426.76 189.01 426.76 242.36 426.76 295.71 480.11 295.71 533.46 295.71 586.81 295.71 586.81 242.36 533.46 242.36"
        />
        <polygon
          className="cls-3"
          points="320.09 28.99 266.74 28.99 213.4 28.99 213.4 82.31 213.4 135.66 213.4 189.01 213.4 242.36 213.4 295.71 266.74 295.71 320.09 295.71 373.41 295.71 373.41 242.36 373.41 189.01 320.09 189.01 320.09 242.36 266.74 242.36 266.74 189.01 320.09 189.01 320.09 135.66 266.74 135.66 266.74 82.31 320.09 82.31 320.09 135.66 373.41 135.66 373.41 82.31 373.41 28.99 320.09 28.99"
        />
        <path
          className="cls-2"
          d="M106.7,29H0V295.71H160.05V29Zm0,106.67v106.7H53.35v-160H106.7Z"
        />
        <polygon
          className="cls-4"
          points="960.22 266.71 1013.57 266.71 1013.57 213.37 1013.57 160.02 960.22 160.02 960.22 213.37 960.22 266.71"
        />
        <polygon
          className="cls-4"
          points="960.22 0 960.22 53.32 960.22 106.67 1013.57 106.67 1013.57 53.32 1013.57 0 960.22 0"
        />
        <rect
          className="cls-4"
          x="906.87"
          y="106.67"
          width="53.35"
          height="53.35"
        />
        <polygon
          className="cls-4"
          points="853.52 213.37 853.52 266.71 906.87 266.71 906.87 213.37 906.87 160.02 853.52 160.02 853.52 213.37"
        />
        <polygon
          className="cls-4"
          points="906.87 0 853.52 0 853.52 53.32 853.52 106.67 906.87 106.67 906.87 53.32 906.87 0"
        />
        <path
          className="cls-5"
          d="M746.85,0H640.16V266.71h160V0Zm0,106.67v106.7H693.5v-160h53.35Z"
        />
        <polygon
          className="cls-4"
          points="533.46 213.37 480.11 213.37 480.11 160.02 480.11 106.67 480.11 53.32 480.11 0 426.76 0 426.76 53.32 426.76 106.67 426.76 160.02 426.76 213.37 426.76 266.71 480.11 266.71 533.46 266.71 586.81 266.71 586.81 213.37 533.46 213.37"
        />
        <polygon
          className="cls-6"
          points="320.09 0 266.74 0 213.4 0 213.4 53.32 213.4 106.67 213.4 160.02 213.4 213.37 213.4 266.71 266.74 266.71 320.09 266.71 373.41 266.71 373.41 213.37 373.41 160.02 320.09 160.02 320.09 213.37 266.74 213.37 266.74 160.02 320.09 160.02 320.09 106.67 266.74 106.67 266.74 53.32 320.09 53.32 320.09 106.67 373.41 106.67 373.41 53.32 373.41 0 320.09 0"
        />
        <path
          className="cls-5"
          d="M106.7,0H0V266.71H160.05V0Zm0,106.67v106.7H53.35v-160H106.7Z"
        />
      </IconTitleStyled>
  );
}