import styled from 'styled-components';
import { media } from '../../helpers';

const LevelInfoStyled = styled.div`
  grid-area: levelInfo;
  flex-direction: column;
  width: 20rem;
  z-index: 2;

  ${
    media.tablet`
      width: auto;
    `
  }

  .game-year-century {
    font-size: 1.5rem;
    text-transform: lowercase;
  }

  img {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .game-year {
    font-family: ${({ theme }) => theme.numbersFont};
  }

  &,
  & .game-name,
  & .game-level-block {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  & .game-name,
  & .game-level-block {
    width: 100%;
  }
  & .game-level-block {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .game-name {
    font-size: 2rem;

    ${
      media.tablet`
        display: none;
      `
    }
  }

  &.zone_80s .game-level-block {
    padding: 3rem 1rem 4rem;
  
    ${
      media.tablet`
        border: none;
        white-space: nowrap;
        padding: 0 2rem 2rem;
      `
    }

    ${
      media.mobileL`
        padding: 0 1.2rem 2rem;
      `
    }
  }

  &.zone_80s .game-name,
  &.zone_80s .game-level-block {
    border: .3rem solid var(--c-80s);
  }
  &.zone_80s .game-level-block {
    ${
      media.tablet`
        border: none;
      `
    }
  }
  &.zone_80s .game-name {
    border-bottom: none;
    padding: 2rem 1rem;
  }

  &.zone_80s .game-year {
    font-size: 2rem;
  
    ${
      media.tablet`
        margin-top: 0;
      `
    }

    ${
      media.mobileL`
        font-size: 1.8rem;
      `
    }
  }

  &.zone_80s .game-level {
    font-size: 3.5rem;
    margin-top: 2rem;
  
    ${
      media.tablet`
        font-size: 2.5rem;
        margin-top: 0;
      `
    }

    ${
      media.mobileL`
        font-size: 2rem;
      `
    }
  }

  &.zone_90s {
    position: relative;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.numbersFont};
  }

  &.zone_90s img {
    display: block;

    ${
      media.tablet`
        display: none;
      `
    }
  }

  &.zone_90s .game-name,
  &.zone_90s .game-level-block {
    z-index: 2;
  }

  &.zone_90s .game-name {
    padding-top: 8rem;
    font-size: .8rem;
    font-weight: bold;
  }

  &.zone_90s .game-year {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 4rem;

    ${
      media.tablet`
        font-size: 1.2rem;
        margin-top: 0;
      `
    }

    ${
      media.mobileM`
        font-size: 1rem;
      `
    }
  }

  &.zone_90s .game-year-century {
    ${
      media.tablet`
        font-size: 1rem;
      `
    }
  }

  &.zone_90s .game-level-block {
    padding: 0 1rem 6rem;

    ${
      media.tablet`
        padding: 0 1rem 2rem;
        white-space: nowrap;
        color: var(--c-white);
      `
    }
  }

  &.zone_90s .game-level {
    font-weight: bold;
    font-size: 1.8rem;
    margin-top: 1.5rem;

    ${
      media.tablet`
        font-size: 1rem;
        margin-top: 1rem;
      `
    }
  }

  &.zone_20s {
    border-radius: .5rem;
  }
  &.zone_20s .game-name {
    background-color: var(--c-white);
    padding: 2rem 1rem;
    font-weight: bold;
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }
  &.zone_20s .game-level {
    font-size: 4rem;
    margin-top: 3rem;
    font-weight: bold;

    ${
      media.tablet`
        font-size: 2rem;
        margin-top: .5rem;
      `
    }
  }
  &.zone_20s .game-year {
    font-size: 2rem;

    ${
      media.tablet`
        font-size: 1.8rem;
      `
    }
  }
  &.zone_20s .game-level-block {
    color: var(--c-white);
    background-color: var(--c-light-4);
    padding: 4rem 1rem;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    box-shadow: 0px 1rem 0px 0px var(--c-light-6);

    ${
      media.tablet`
        background-color: transparent;
        padding: 0 1.5rem;
        box-shadow: none;
        white-space: nowrap;
      `
    }

    ${
      media.mobileM`
        padding: 0 1.3rem;
      `
    }
  }
`
export default LevelInfoStyled;