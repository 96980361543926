import React, {FunctionComponent, useCallback, useEffect} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ModalProps } from '../../models';

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;

  &.default {
    background-color: var(--c-gray-5);
    opacity: .7;
  }
  &.black {
    background-color: var(--c-black);
  }
  &.transparent {
    background-color: transparent;
  }
`;

export const Modal: FunctionComponent<ModalProps> = ({
  visible,
  hide,
  children,
  className = 'default',
  target = null
}) => {
  const modaltarget = target || document.body;
  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (visible && e.key === 'Escape') {
      hide()
    }
  }, [hide, visible]);

  useEffect(() => {
    document.addEventListener('keyup', escapeListener)
    return () => {
      document.removeEventListener('keyup', escapeListener)
    }
  }, [escapeListener])

  const modal = (
    <React.Fragment>
      <Backdrop
        onClick={() => hide()}
        className={className}
      />
      <Wrapper>
        { children }
      </Wrapper>
    </React.Fragment>
  );
  return visible ? ReactDOM.createPortal(modal, modaltarget) : null;
};