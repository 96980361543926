import React from 'react';
import { Zone } from '../../models';
import BaseImg from '../ui/BaseImg';
import left_bg_80s_3x from '../../assets/graphics/80s/80s-bg/80s_bg_left@3x.jpg';
import left_bg_80s_2x from '../../assets/graphics/80s/80s-bg/80s_bg_left@2x.jpg';
import left_bg_80s from '../../assets/graphics/80s/80s-bg/80s_bg_left.jpg';
import right_bg_80s_3x from '../../assets/graphics/80s/80s-bg/80s_bg_right@3x.jpg';
import right_bg_80s_2x from '../../assets/graphics/80s/80s-bg/80s_bg_right@2x.jpg';
import right_bg_80s from '../../assets/graphics/80s/80s-bg/80s_bg_right.jpg';
import IconCoinsBg90s from '../icons/IconCoinsBg90s';
import IconBugBg90s from '../icons/IconBugBg90s';
import { BgZoneImagesProps } from '../../models';
import { getSrcset } from '../../helpers';

export default function BgZoneImages({zone}: BgZoneImagesProps) {
  if (zone === Zone.ZONE_20s) return null;
  const srcSetLeft = getSrcset([left_bg_80s, left_bg_80s_2x, left_bg_80s_3x]);
  const srcSetRight = getSrcset([right_bg_80s, right_bg_80s_2x, right_bg_80s_3x]);
  return (
    <>
      { zone === Zone.ZONE_80s
        ? <>
          <div className="green-line"></div>
          <BaseImg
            src={left_bg_80s_3x}
            srcset={srcSetLeft}
            className="bg-img-left"
          />
          <BaseImg
            src={right_bg_80s_3x}
            srcset={srcSetRight}
            className="bg-img-right"
          /> 
        </>
        : <>
          <div className="bg-img-left">
            <IconCoinsBg90s />
          </div>
          <div className="bg-img-right">
            <IconBugBg90s />
          </div>
        </>
      }
    </>
  );
}