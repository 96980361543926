import React, { useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeItem, Zone } from '../models';
import {
  theme80s,
  themeConfig
} from './themes';
import ThemeContext from './themeContext';

export default function ThemeProvider(props: any) {
  const [theme, setTheme] = useState<ThemeItem>(theme80s);
  const [zone, setZone] = useState<Zone>(Zone.ZONE_80s);

  const changeZone = (zone: Zone) => {
    setZone(zone);
    setTheme(themeConfig[zone]);
  }

  return (
    <ThemeContext.Provider value={{zone, changeZone}}>
      <StyledThemeProvider theme={theme}>
        {props.children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
}
