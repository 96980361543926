import { createContext } from 'react';
import { Zone } from '../models';

interface ZoneContext {
  zone: Zone;
  changeZone: (zone: Zone) => void;
}

const ThemeContext = createContext<ZoneContext>({
    zone: Zone.ZONE_80s,
    changeZone: () => {},
});
export default ThemeContext;