import styled from 'styled-components';
import btn_bg_90s from '../../assets/graphics/90s/90s_button_rectangle.svg';
import { media } from '../../helpers';

const LevelDescriptionModalStyled = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 2rem 4rem;
  z-index: 2;

  ${
    media.mobileM`
      padding: 1.5rem 2rem 2rem;
    `
  }

  &.zone_90s {
    padding: 2rem 3rem;

    ${
      media.mobileM`
        padding: 1.5rem 3rem;
      `
    }
  }

  & .description {
    display: block;
    white-space: pre-wrap;
    width: 100%;
    padding: 0 1rem 2rem;
    text-align: left;

    ${
      media.mobileM`
        padding: 0 1rem 1.5rem;
      `
    }
  }

  .image {
    height: 5rem;
    margin: 4rem 0;

    ${
      media.mobileM`
        height: 4rem;
        margin: 2rem 0 3rem;
      `
    }

    & img {
      width: auto;
    }
  }

  button {
    padding: 0 3rem;
    height: 6rem;
    font-size: 2rem;
    margin-top: 3rem;

    ${
      media.mobileM`
        margin-top: 2rem;
      `
    }
  }

  & .level {
    display: block;
    margin: 0 0 2rem 0;
    font-size: 4.5rem;
    vertical-align: middle;
  }

  &.zone_80s button {
    width: 100%;
    border: .2rem solid var(--c-gray-1);
    background-color: var(--c-80s);
    color: var(--c-80s-dark);
    font-weight: bold;
  }

  &.zone_80s {
    background-color: var(--c-80s-dark);
    border: .2rem solid var(--c-80s);
  }

  &.zone_80s .level {
    text-transform: uppercase;

    ${
      media.mobileM`
        font-size: 3.5rem;
      `
    }
  }

  &.zone_90s {
    font-family: ${({ theme }) => theme.numbersFont};
  }

  &.zone_90s button {
    background-color: transparent;
    background-image: url(${btn_bg_90s});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    ${
      media.mobileM`
        margin-bottom: 2rem;
      `
    }
  }

  &.zone_90s .level {
    text-transform: lowercase;
    font-size: 2.5rem;
  }
  &.zone_90s .description {
    font-size: 1rem;
    line-height: 1.3rem;
  }

  &.zone_20s {
    font-family: ${({ theme }) => theme.numbersFont};
    font-weight: bold;
    ${
      media.mobileM`
        padding: 1.5rem 2rem 3rem;
      `
    }
  }

  &.zone_20s .description {
    text-align: center;
  }

  &.zone_20s button {
    background-color: var(--c-light-2);
    font-size: 2rem;
    width: 20rem;
    border-radius: .8rem;
    font-weight: bold;
  }
  &.zone_20s .image {
    margin: 4rem 0 2rem;
  }
  &.zone_20s .level {
    font-size: 3.5rem;
  }
`
export default LevelDescriptionModalStyled;