import { Zone } from "../models";

export default function soundManager(zone: Zone) {

  if (zone === Zone.ZONE_80s) {
    return {
      bg_sound: require('../assets/sounds/80s/80s_background.mp3').default,
      line_cleared_sound: require('../assets/sounds/80s/80s_line_cleared.mp3').default,
      block_hit_bottom_sound: require('../assets/sounds/80s/80s_block_hit_bottom.mp3').default,
      block_rotate_sound: require('../assets/sounds/80s/80s_block_rotate.mp3').default,
      level_lost_sound: require('../assets/sounds/80s/80s_level_lost.mp3').default,
      level_won_sound: require('../assets/sounds/80s/80s_level_won.mp3').default,
    }
  } else if (zone === Zone.ZONE_20s) {
    return {
      bg_sound: require('../assets/sounds/20s/20s_background.mp3').default,
      line_cleared_sound: require('../assets/sounds/20s/20s_line_cleared.mp3').default,
      block_hit_bottom_sound: require('../assets/sounds/20s/20s_block_hit_bottom.mp3').default,
      block_rotate_sound: require('../assets/sounds/20s/20s_block_rotate.mp3').default,
      level_lost_sound: require('../assets/sounds/20s/20s_level_lost.mp3').default,
      level_won_sound: require('../assets/sounds/20s/20s_level_won.mp3').default,
    }
  }
  else {
    return {
      bg_sound: require('../assets/sounds/90s/90s_background.mp3').default,
      line_cleared_sound: require('../assets/sounds/90s/90s_line_cleared.mp3').default,
      block_hit_bottom_sound: require('../assets/sounds/90s/90s_block_hit_bottom.mp3').default,
      block_rotate_sound: require('../assets/sounds/90s/90s_block_rotate.mp3').default,
      level_lost_sound: require('../assets/sounds/90s/90s_level_lost.mp3').default,
      level_won_sound: require('../assets/sounds/90s/90s_level_won.mp3').default,
    }
  }
}