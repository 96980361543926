import React, { useEffect, useState } from 'react';
import { Zone } from '../../models';
import IconMute from '../icons/IconMute';
import BaseButton from './BaseButton';
import { MuteButtonProps } from '../../models';

export default function MuteButton({callback, className, zone}: MuteButtonProps) {
  const [isMute, setIsMute] = useState(false);
  const [color, setColor] = useState('#fff');

  useEffect(() => {
    if (zone === Zone.ZONE_80s) {
      setColor('#1aed1f');
    } else {
      setColor('#fff')
    }
  }, [zone])

  useEffect(() => {
    const muteState = localStorage.getItem('isMute');
    setIsMute(!!muteState);
  }, [])

  useEffect(() => {
    callback(isMute);
    if (isMute) {
      localStorage.setItem('isMute', 'true');
    } else {
      localStorage.removeItem('isMute');
    }
  }, [isMute, callback])

  const toggleState = () => {
    setIsMute(!isMute);
  }

  return (
    <BaseButton
      handler={toggleState}
      className={className || ''}
    >
      <IconMute
        color={color}
        isMute={isMute}
      />
    </BaseButton>
  );
}