import styled from 'styled-components';
import { media } from '../../helpers';

const GamePageStyled = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'levelInfo board timer'
    'levelInfo board points';
  grid-template-columns: 20rem auto 20rem;
  column-gap: 1.5rem;
  align-content: start;
  justify-content: center;
  align-items: start;
  justify-items: center;
  padding: 5rem;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  ${
    media.laptop`
      padding: 5rem 3rem 2rem;
    `
  }

  ${
    media.tablet`
    padding: 3rem 1rem 1rem;
    grid-template-columns: repeat(3, min-content);
    column-gap: 1rem;
    row-gap: 2rem;
    grid-template-areas:
      'points levelInfo timer'
      'board board board'
      'controls controls controls';
    `
  }

  ${
    media.mobileL`
      column-gap: .5rem;
    `
  }

  &.zone_80s {
    background-color: var(--c-dark-1);
  }
  &.zone_90s {
    background-color: #355643;
  }

  .game-background {
    position: absolute;
    top: 0;
    left: 0
    width: 100%;
    height: 100%;
    z-index: 0;

    & img {
      object-fit: cover;
      object-position: bottom;
    }

    & .desktop-image {
      ${
        media.tablet`
          display: none;
        `
      }
    }
    & .mobile-image {
      display: none;
      ${
        media.tablet`
          display: block;
        `
      }
    }
  }

  &.zone_90s .game-background {
    height: 81.5rem;

    ${
      media.desktopXL`
        height: 57.5rem;
      `
    }

    ${
      media.laptop`
        height: 56rem;
      `
    }

    ${
      media.tablet`
        height: 100%;
      `
    }
  }

  .timer-wrapper {
    grid-area: timer;
    position: relative;
    justify-self: flex-start;
  }

  .mute-button {
    height: 2rem;
    position: absolute;
    top: -3rem;
    right: 0;
    background-color: transparent;

    ${
      media.tablet`
        top: -2rem;
        height: 1.5rem;
      `
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5rem;
      height: 4rem;

      ${
        media.tablet`
          width: 4rem;
          height: 2.5rem;
        `
      }
    }
  }
  &.zone_90s .mute-button {
    right: 4rem;

    ${
      media.tablet`
        right: 0;
      `
    }
  }
  &.zone_20s .mute-button {
    top: 0;

    ${
      media.tablet`
        top: -2rem;
      `
    }
  }
`
export default GamePageStyled;