import { createStore, combineReducers, compose } from 'redux'
import user from './user/reducers';
import game from './game/reducers';

const rootReducer = combineReducers({
  user,
  game,
});

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose || compose;

const store = createStore(
  rootReducer,
  composeEnhancers()
);

export type RootState = ReturnType<typeof rootReducer>;

export {
  rootReducer,
  store
}