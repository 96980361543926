import { useEffect, useRef, useState } from 'react';
import {
  moveLeftEvent,
  moveRightEvent,
  moveDownEvent,
  rotateEvent,
} from '../../helpers';
import { Coords } from '../../models';

export default function GameMobileControls() {
  const [step, setStep] = useState<number | null>(null);
  const [touchStartTimestamp, setTouchStartTimestamp] = useState<null | number>(null);
  const ref = useRef<Coords | null>(null);

  useEffect(() => {
    const id = setTimeout(() => {
      const lastElem = document.body.getElementsByClassName('block-empty')[0];
      setStep(lastElem ? lastElem.getBoundingClientRect().width / 2 : null);
    }, 100)
    return () => clearTimeout(id);
  }, [])

  useEffect(() =>  {
    const options = {
      capture: false,
      passive: false,
    }
    function move(e: TouchEvent) {
      e.preventDefault();
      if (touchStartTimestamp) {
        setTouchStartTimestamp(null)
      }
      const touches = e.touches;
      if (!step) return;
      if (ref.current) {
        const newX = touches[0].clientX;
        const newY = touches[0].clientY;
        const prevX = ref.current.x;
        const prevY = ref.current.y;
        const isRight = newX > prevX;
        const diffX = Math.abs(newX - prevX);
        if ((diffX / step) > 1) {
          ref.current.x = isRight ? prevX + step : prevX - step;
          document.body.dispatchEvent(isRight ? moveRightEvent : moveLeftEvent);
        }
        if (newY > prevY) {
          const diffY = newY - prevY;
          if ((diffY / step) > 1) {
            ref.current.y = prevY + step;
            document.body.dispatchEvent(moveDownEvent);
          }
        }
      } else {
        ref.current = {
          x: touches[0].clientX,
          y: touches[0].clientY,
        }
      }
    }
    function touchEnd(e: TouchEvent) {
      const isRotate = touchStartTimestamp
        && e.timeStamp - touchStartTimestamp <= 150;
      if (isRotate) {
        document.body.dispatchEvent(rotateEvent);
        setTouchStartTimestamp(null);
      }
      if (ref.current) {
        ref.current = null;
      }
    }
    function touchStart(e: TouchEvent) {
      setTouchStartTimestamp(e.timeStamp);
    }
    document.body.addEventListener("touchstart", touchStart, options);
    document.body.addEventListener("touchend", touchEnd, options);
    document.body.addEventListener("touchmove", move, options);
    return () => {
      document.body.removeEventListener("touchstart", touchStart, options);
      document.body.removeEventListener("touchend", touchEnd, options);
      document.body.removeEventListener("touchmove", move, options);
    }
  }, [step, touchStartTimestamp])

  return null;
}
