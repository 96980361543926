import React from 'react';
import { Bg90sColumnProps } from '../../models';
import BaseImg from '../ui/BaseImg';
import { getSrcset } from '../../helpers';
import green_row_3x from '../../assets/graphics/90s/green-row/90s_green_row@3x.png';
import green_row_2x from '../../assets/graphics/90s/green-row/90s_green_row@2x.png';
import green_row from '../../assets/graphics/90s/green-row/90s_green_row.png';

export default function Bg90sGreenRow({ position }: Bg90sColumnProps) {
  const wrapperClass = `img img__green-row img__green-row--${position}`;
  const set = getSrcset([green_row, green_row_2x, green_row_3x])
  return (
    <BaseImg
      src={green_row_3x}
      srcset={set}
      className={wrapperClass}
    />
  );
}
