import React from 'react';

export default function TetrisBoardFakeCells() {
  return (
    <div className="fake-cells">
      {
        Array.from(Array(13)
          .keys())
          .map(item => <div key={item} className="fake-cell"></div>)
      }
    </div>
  );
}
