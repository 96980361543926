import React from 'react';
import styled from 'styled-components';

const IconShareModernStyled = styled.svg`
  height: 100%;
  width: 100%;
`

export default function IconShareModern() {
  return (
      <IconShareModernStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 105.94"
      >
        <path d="M82,33.86a1.29,1.29,0,0,1,1.29,1.29V70.79A1.29,1.29,0,0,1,82,72.08H32.88a1.29,1.29,0,0,1-1.29-1.29V35.15a1.29,1.29,0,0,1,1.29-1.29H82M82,31H32.88a4.18,4.18,0,0,0-4.17,4.18V70.79A4.17,4.17,0,0,0,32.88,75H82a4.17,4.17,0,0,0,4.18-4.17V35.15A4.18,4.18,0,0,0,82,31Z"/>
        <polygon points="57.42 56.84 30.13 33.09 32.03 30.92 57.42 53.02 82.81 30.92 84.7 33.09 57.42 56.84"/>
      </IconShareModernStyled>
  );
}