import { Zone, BaseObj, Resolution, LevelStatus } from "../models";
import { css, FlattenSimpleInterpolation } from 'styled-components'
import {
  LevelConfigMap,
  LevelInfoWithJSON,
  LevelProgress,
  LevelProgressModel
} from "../models/GameModel";

function defineParamDependOnZone(zone: Zone, list: any[]) {
  switch(true) {
    case zone === Zone.ZONE_80s:
      return list[0];
    case zone === Zone.ZONE_90s:
      return list[1];
    case zone === Zone.ZONE_20s:
      return list[2];
    default:
      return list[0];
  }
}

function defineParamDependOnLevel(level: number, list: any[]) {
  switch(true) {
    case level >= 1 && level <= 3:
      return list[0];
    case level >= 4 && level <= 6:
      return list[1];
    case level >= 7 && level <= 8:
      return list[2];
    default:
      return list[0];
  }
}


function defineNextZoneOnLevel(level: number) {
  switch(true) {
    case level <= 3:
      return Zone.ZONE_80s;
    case level >= 4 && level <= 6:
      return Zone.ZONE_90s;
    case level >= 7:
      return Zone.ZONE_20s;
    default:
      return Zone.ZONE_80s;
  }
}

function defineStatus(status: LevelStatus, level: number) {
  switch (true) {
    case status === LevelStatus.LEVEL_COMPLETE:
      return [`Level ${level}`, 'Complete!']
    case status === LevelStatus.GAME_COMPLETE:
      return ['Game', 'Complete!']
    case status === LevelStatus.GAME_OVER:
      return ['Game', 'Over']
    default:
      return ['', '']
  }
}

const isDeviceMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
};

function clone<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

const sizes: BaseObj = {
  mobileS: '320px',
  mobileM: '360px',
  mobileL: '500px',
  tablet: '768px',
  laptop: '1024px',
  desktopS: '1152px',
  desktopM: '1336px',
  desktopL: '1440px',
  desktopXL: '1920px'
}

const media = Object.keys(sizes).reduce((acc: BaseObj<(styles: TemplateStringsArray) => FlattenSimpleInterpolation>, label) => {
   acc[label] = (styles: TemplateStringsArray) => css`
      @media (max-width: ${sizes[label]}) {
         ${css(styles)};
      }
   `
   return acc
}, {})

const stopEvent = new CustomEvent('STOP_GAME', {
  detail: {},
  bubbles: true,
  cancelable: true,
  composed: false,
});

const pauseEvent = new KeyboardEvent('keydown', {
  code: 'KeyP',
  bubbles: true,
  key: 'p',
  keyCode: 80
});

const rotateEvent = new KeyboardEvent('keydown', {
  code: 'ArrowUp',
  bubbles: true,
  key: 'ArrowUp',
  keyCode: 38
});

const moveRightEvent = new KeyboardEvent('keydown', {
  code: 'ArrowRight',
  bubbles: true,
  key: 'ArrowRight',
  keyCode: 39
});

const moveLeftEvent = new KeyboardEvent('keydown', {
  code: 'ArrowLeft',
  bubbles: true,
  key: 'ArrowLeft',
  keyCode: 37
});

const restartEvent = new KeyboardEvent('keydown', {
  code: 'KeyR',
  bubbles: true,
  key: 'r',
  keyCode: 82
});

const moveDownEvent = new KeyboardEvent('keydown', {
  code: 'ArrowDown',
  bubbles: true,
  key: 'ArrowDown',
  keyCode: 40
});

function getDisplayWidth(width: number): Resolution {
  if (width > 1920) {
    return Resolution.DESKTOP_XL
  } else if (width > 1440) {
    return Resolution.DESKTOP_L
  } else if (width > 1366) {
    return Resolution.DESKTOP_M
  } else if (width > 1152) {
    return Resolution.DESKTOP_S
  } else if (width > 1024) {
    return Resolution.LAPTOP
  } else if (width > 768) {
    return Resolution.TABLET;
  } else if (width > 500) {
    return Resolution.MOBILE_L;
  } else if (width > 360) {
    return Resolution.MOBILE_M;
  } else if (width > 320) {
    return Resolution.MOBILE_S;
  } else {
    return Resolution.MOBILE_XS;
  }
}

function getSrcset(list: string[], isBg = false) {
  return !isBg
    ? `${list[0]}, ${list[1]} 2x, ${list[2]} 3x`
    : `url(${list[0]}) 1x, url(${list[1]}) 2x, url(${list[2]}) 3x`
}

function convertLevelProgress(list: LevelProgressModel[]) {
  const obj: LevelProgress = {};
  list.forEach(item => {
    obj[item.level_id] = item.score;
  })
  return obj;
}

function convertLevelConfig(list: LevelInfoWithJSON[]) {
  const obj: LevelConfigMap = {};
  list.forEach(item => {
    obj[item.id] = JSON.parse(item.config);
  })
  return obj;
}

export {
  media,
  pauseEvent,
  restartEvent,
  moveRightEvent,
  moveLeftEvent,
  moveDownEvent,
  rotateEvent,
  stopEvent,
  isDeviceMobile,
  clone,
  convertLevelConfig,
  convertLevelProgress,
  defineParamDependOnLevel,
  defineNextZoneOnLevel,
  defineParamDependOnZone,
  getDisplayWidth,
  defineStatus,
  getSrcset
}