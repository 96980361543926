import React from 'react';
import styled from 'styled-components';
import { BaseButtonProps } from '../../models';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`

export default function BaseButton({handler, children, className}: BaseButtonProps) {
  return (
    <Button
      onClick={() => handler()}
      className={className || ''}
    >
      {children}
    </Button>
  );
}