import React from 'react';
import { Zone, NextBlockBoxProps } from '../../models';
import next_box_90s_3x from '../../assets/graphics/90s/next-box/90s_next_box@3x.png';
import next_box_90s_2x from '../../assets/graphics/90s/next-box/90s_next_box@2x.png';
import next_box_90s from '../../assets/graphics/90s/next-box/90s_next_box.png';
import next_box_20s_3x from '../../assets/graphics/20s/next-box/20s_next_box@3x.png';
import next_box_20s_2x from '../../assets/graphics/20s/next-box/20s_next_box@2x.png';
import next_box_20s from '../../assets/graphics/20s/next-box/20s_next_box.png';
import BaseImg from '../ui/BaseImg';
import { getSrcset } from '../../helpers';

const images = {
  [Zone.ZONE_80s]: null,
  [Zone.ZONE_90s]: {
    src: next_box_90s_3x,
    srcset: getSrcset([next_box_90s, next_box_90s_2x, next_box_90s_3x])
  },
  [Zone.ZONE_20s]: {
    src: next_box_20s_3x,
    srcset: getSrcset([next_box_20s, next_box_20s_2x, next_box_20s_3x])
  },
}

export default function NextBlockBox({ children, className, zone }: NextBlockBoxProps) {
  const nextBlockBg = images[zone];
  return (
    <div className={`next-block ${className}`}>
      {
        nextBlockBg
        && <BaseImg
          src={nextBlockBg.src}
          srcset={nextBlockBg.srcset}
          className="img__next-block"
        />
      }
      <div className="queue-wrapper">
        { children }
      </div>
    </div>
  );
}
