import { clone } from "../../helpers";
import { LevelProgress } from "../../models/GameModel";
import { BaseAction, ActionType, GameState } from "./types";

const initialState: GameState = {
  currentLevel: null,
  levelsConfig: null,
  nextLevel: 1,
  levelResult: null,
  score: 0,
  progress: null,
};

export default function game(
  state = initialState,
  action: BaseAction,
): GameState {
  switch (action.type) {
    case ActionType.SET_LEVELS_CONFIG:
      return {
        ...state,
        levelsConfig: action.payload
      };
    case ActionType.SET_CURRENT_LEVEL:
      return {
        ...state,
        currentLevel: action.payload
      };
    case ActionType.RESET_STATE:
      return {
        ...initialState,
        progress: state.progress,
        levelsConfig: state.levelsConfig
      };
    case ActionType.SET_NEXT_LEVEL:
      return {
        ...state,
        nextLevel: action.payload
      };
    case ActionType.SET_LEVEL_RESULT:
      return {
        ...state,
        score: action.payload
          ? state.score + action.payload.score
          : state.score,
        levelResult: action.payload
      };
    case ActionType.SET_USER_PROGRESS:
      return {
        ...state,
        progress: action.payload
      };
    case ActionType.UPDATE_USER_PROGRESS:
      if (!state.progress) return state;
      const { score, level_id } = action.payload;
      const copyObject = clone<LevelProgress>(state.progress);
      copyObject[level_id] = score;
      return {
        ...state,
        progress: copyObject
      };
    default:
      return state;
  }
}