import React from 'react';
import { Bg90sColumnProps } from '../../models';
import column_3x from '../../assets/graphics/90s/column/90s_column@3x.png';
import column_2x from '../../assets/graphics/90s/column/90s_column@2x.png';
import column from '../../assets/graphics/90s/column/90s_column.png';
import BaseImg from '../ui/BaseImg';
import IconColumnTopper from '../icons/IconColumnTopper';
import { getSrcset } from '../../helpers';

export default function Bg90sGreenRow({ position }: Bg90sColumnProps) {
  const wrapperClass = `img img__column img__column--${position}`;
  const set = getSrcset([column, column_2x, column_3x]);
  return (
    <div className={wrapperClass}>
      <IconColumnTopper className="img__column-topper" />
      <BaseImg
        src={column_3x}
        srcset={set}
      />
    </div>
  );
}
