import styled from 'styled-components';
import dashed_80s from '../../assets/graphics/80s/80s_dashed_border.svg';
import block_green_3x from '../../assets/graphics/90s/blocks/90s_block_green@3x.png';
import block_green_2x from '../../assets/graphics/90s/blocks/90s_block_green@2x.png';
import block_green from '../../assets/graphics/90s/blocks/90s_block_green.png';
import block_blue_3x from '../../assets/graphics/90s/blocks/90s_block_blue@3x.png';
import block_blue_2x from '../../assets/graphics/90s/blocks/90s_block_blue@2x.png';
import block_blue from '../../assets/graphics/90s/blocks/90s_block_blue.png';
import block_orange_3x from '../../assets/graphics/90s/blocks/90s_block_orange@3x.png';
import block_orange_2x from '../../assets/graphics/90s/blocks/90s_block_orange@2x.png';
import block_orange from '../../assets/graphics/90s/blocks/90s_block_orange.png';
import block_pink_3x from '../../assets/graphics/90s/blocks/90s_block_pink@3x.png';
import block_pink_2x from '../../assets/graphics/90s/blocks/90s_block_pink@2x.png';
import block_pink from '../../assets/graphics/90s/blocks/90s_block_pink.png';
import block_purple_3x from '../../assets/graphics/90s/blocks/90s_block_purple@3x.png';
import block_purple_2x from '../../assets/graphics/90s/blocks/90s_block_purple@2x.png';
import block_purple from '../../assets/graphics/90s/blocks/90s_block_purple.png';
import block_red_3x from '../../assets/graphics/90s/blocks/90s_block_red@3x.png';
import block_red_2x from '../../assets/graphics/90s/blocks/90s_block_red@2x.png';
import block_red from '../../assets/graphics/90s/blocks/90s_block_red.png';
import block_yellow_3x from '../../assets/graphics/90s/blocks/90s_block_yellow@3x.png';
import block_yellow_2x from '../../assets/graphics/90s/blocks/90s_block_yellow@2x.png';
import block_yellow from '../../assets/graphics/90s/blocks/90s_block_yellow.png';
import { getSrcset, media } from '../../helpers';

const greenBlockSet = getSrcset([block_green, block_green_2x, block_green_3x], true);
const blueBlockSet = getSrcset([block_blue, block_blue_2x, block_blue_3x], true);
const orangeBlockSet = getSrcset([block_orange, block_orange_2x, block_orange_3x], true);
const pinkBlockSet = getSrcset([block_pink, block_pink_2x, block_pink_3x], true);
const purpleBlockSet = getSrcset([block_purple, block_purple_2x, block_purple_3x], true);
const redBlockSet = getSrcset([block_red, block_red_2x, block_red_3x], true);
const yellowBlockSet = getSrcset([block_yellow, block_yellow_2x, block_yellow_3x], true);

const TetrisStyled = styled.div`
  position: relative;
  grid-area: board;

  &.zone_80s .game-board-wrapper table {
    box-shadow: 0 0 0 .3rem var(--c-80s);
  }

  .table-wrapper {
    position: relative;
    z-index: 5;
  }

  .hidden {
    filter: brightness(.4);
  }

  .game-board tr:last-child .piece-preview {
    border-bottom: none;
  }

  &.zone_90s .game-board {
    display: flex;
    flex-direction: column;
    background-color: var(--c-90s-game-area-bg);
    grid-area: center;
    border: .25rem solid var(--c-90s-game-area-bg);
    border-bottom: none;
  }

  .game-board-wrapper {
    display: flex;
    z-index: 5;
  }

  .queue-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &.zone_80s .game-board-wrapper {
    border: .2rem solid transparent;
    border-image: url(${dashed_80s}) stretch;
    padding: 0 1rem;
    border-image-slice: 5;
  }

  .border-image {
    height: 100%;
    width: 1rem;
  }

  .border-image__left {
    margin-right: 2rem;
  }

  .border-image__right {
    margin-left: 2rem;
  }

  .game-block {
    margin: 0;
    padding: 0;
    width: 3rem;
    height: 3rem;

    ${
      media.desktopXL`
        width: 2rem;
        height: 2rem;
      `
    }

    ${
      media.tablet`
        width: 1.8rem;
        height: 1.8rem;
      `
    }
  }

  .game-board {
    border-spacing: 0;
    border-collapse: collapse;

  }

  .img__next-block {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: -1;
  }
    
  .next-block {
    width: 8rem;
    height: 6rem;
    position: absolute;
    left: 50%;
    top: .5rem;
    transform: translate(-50%, -50%);
    background-color: var(--c-white);
    z-index: 11;
  }

  .next-block table {
    border: none;
    border-spacing: 0;
  }
  
  .next-block .game-block {
    margin: 0;
    padding: 0;
    width: 1rem;
    height: 1rem;
    border: none;
  }

  &.zone_80s .game-block {
    border: .1rem solid var(--c-gray-1);
  }
  &.zone_80s .game-board {
    background-color: var(--c-80s-dark);
  }

  &.zone_80s .piece-i,
  &.zone_80s .piece-j,
  &.zone_80s .piece-l,
  &.zone_80s .piece-o,
  &.zone_80s .piece-s,
  &.zone_80s .piece-t,
  &.zone_80s .piece-z {
    background-color: var(--c-80s);
  }

  &.zone_80s .next-block {
    background-color: var(--c-gray-1);
    border: .3rem solid var(--c-80s);
  }

  &.zone_80s .next-block .piece {
    border-radius: .2rem;
    border: .1rem solid var(--c-80s);
    background-color: transparent;
  }

  &.zone_80s .next-block .game-block {
    border-radius: .2rem;
  }

  &.zone_90s .piece-i,
  &.zone_90s .piece-j,
  &.zone_90s .piece-l,
  &.zone_90s .piece-o,
  &.zone_90s .piece-s,
  &.zone_90s .piece-t,
  &.zone_90s .piece-z,
  &.zone_90s .next-block,
  &.zone_20s .next-block {
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.zone_90s .piece-i {
    background-image: image-set(${redBlockSet});
  }
  &.zone_90s .piece-j {
    background-image: image-set(${yellowBlockSet});
  }
  &.zone_90s .piece-l {
    background-image: image-set(${pinkBlockSet});
  }
  &.zone_90s .piece-o {
    background-image: image-set(${purpleBlockSet});
  }
  &.zone_90s .piece-s {
    background-image: image-set(${blueBlockSet});
  }
  &.zone_90s .piece-t {
    background-image: image-set(${greenBlockSet});
  }
  &.zone_90s .piece-z {
    background-image: image-set(${orangeBlockSet});
  }

  &.zone_90s .next-block {
    background-color: transparent;
  }

  &.zone_90s .game-board .game-block,
  &.zone_90s .game-board .fake-cell {
    position: relative;
  }

  &.zone_90s .game-board .game-block:after {
    content: '';
    height: .5rem;
    width: .5rem;
    position: absolute;
    background-color: var(--c-gray-3);
    opacity: .15;
  }
  &.zone_90s .game-board .game-block:after {
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
  }

  &.zone_90s .game-board .game-block:last-child:before {
    content: '';
    height: .5rem;
    width: .5rem;
    position: absolute;
    background-color: var(--c-gray-3);
    opacity: .15;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
  }

  .fake-cells {
    grid-area: helper;
    display: none;
    padding: 0 .25rem;
  }

  &.zone_90s .fake-cells {
    width: 100%;
  }

  &.zone_90s .img__next-block {
    display: block;
  }

  .fake-cell {
    width: 100%;
    height: 0;
    position: relative;
  }

  &.zone_90s .fake-cell:after {
    content: '';
    height: .5rem;
    width: .5rem;
    position: absolute;
    background-color: var(--c-gray-3);
    opacity: .15;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 0);
  }

  &.zone_90s .fake-cell:last-child:before {
    content: '';
    height: .5rem;
    width: .5rem;
    position: absolute;
    background-color: var(--c-gray-3);
    opacity: .15;
    right: 0;
    bottom: 0;
    transform: translate(50%, 0);
  }

  &.zone_90s .game-board-wrapper {
    display: grid;
    grid-template-areas:
      'left top right'
      'left center right'
      'left helper right'
      'left bottom right';
  }

  &.zone_90s .img__green-row--top {
    grid-area: top;
  }

  &.zone_90s .img__green-row--bottom {
    grid-area: bottom;
  }

  &.zone_90s .img__green-row--bottom,
  &.zone_90s .img__green-row--top {
    height: 1.7rem;
    width: 40rem;

    ${
      media.desktopXL`
        width: 26.5rem;
      `
    }

    ${
      media.tablet`
        width: 23.95rem;
      `
    }
    ${
      media.mobileM`
        width: 20rem;
        height: 1.2rem;
      `
    }
  }

  &.zone_90s .img__column--right,
  &.zone_90s .img__column--left {
    width: 3.8rem;
    position: relative;
    z-index: 2;
    ${
      media.tablet`
        width: 3.5rem;
      `
    }
    ${
      media.mobileM`
        width: 2.5rem;
      `
    }
  }
  &.zone_90s .img__column--right {
    grid-area: right;
  }
  &.zone_90s .img__column--left {
    grid-area: left;
  }
  &.zone_90s .img__column-topper {
    position: absolute;
    top: -1.9rem;
    left: 0;
    width: 100%;
    height: auto;
    ${
      media.tablet`
        top: -1.6rem;
      `
    }
  }
  &.zone_90s .game-board .game-block,
  &.zone_90s .game-board .fake-cell {

    ${
      media.mobileM`
        width: 1.5rem;
        height: 1.5rem;
      `
    }
  }

  &.zone_20s .img__next-block {
    display: block;
  }

  &.zone_20s {
    ${
      media.tablet`
        margin-top: 1rem;
      `
    }
    ${
      media.mobileM`
        margin-top: 2rem;
      `
    }
  }

  &.zone_20s .piece-i,
  &.zone_20s .piece-j,
  &.zone_20s .piece-l,
  &.zone_20s .piece-o,
  &.zone_20s .piece-s,
  &.zone_20s .piece-t,
  &.zone_20s .piece-z {
    background-image: none;
    position: relative;
  }

  &.zone_20s .game-block {
    border: .15rem solid var(--c-gray-6);
  }

  &.zone_20s .piece-i-top:after,
  &.zone_20s .piece-i-bottom:after,
  &.zone_20s .piece-i-top-bottom:after,
  &.zone_20s .piece-i-top-bottom:before,
  &.zone_20s .piece-j-top:after,
  &.zone_20s .piece-j-bottom:after,
  &.zone_20s .piece-j-top-bottom:after,
  &.zone_20s .piece-j-top-bottom:before,
  &.zone_20s .piece-l-top:after,
  &.zone_20s .piece-l-bottom:after,
  &.zone_20s .piece-l-top-bottom:after,
  &.zone_20s .piece-l-top-bottom:before,
  &.zone_20s .piece-o-top:after,
  &.zone_20s .piece-o-bottom:after,
  &.zone_20s .piece-o-top-bottom:after,
  &.zone_20s .piece-o-top-bottom:before,
  &.zone_20s .piece-s-top:after,
  &.zone_20s .piece-s-bottom:after,
  &.zone_20s .piece-s-top-bottom:after,
  &.zone_20s .piece-s-top-bottom:before,
  &.zone_20s .piece-t-top:after,
  &.zone_20s .piece-t-bottom:after,
  &.zone_20s .piece-t-top-bottom:after,
  &.zone_20s .piece-t-top-bottom:before,
  &.zone_20s .piece-z-top:after,
  &.zone_20s .piece-z-bottom:after,
  &.zone_20s .piece-z-top-bottom:after,
  &.zone_20s .piece-z-top-bottom:before {
    content: '';
    position: absolute;
    left: -.15rem;
    width: calc(100% + .3rem);
    height: .3rem;
  }
  &.zone_20s .piece-i {
    background-color: var(--c-light-8);
    border: .15rem solid var(--c-light-8);
  }
  &.zone_20s .piece-i-bottom:after,
  &.zone_20s .piece-i-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-8-dark);
  }
  &.zone_20s .piece-i-top:after,
  &.zone_20s .piece-i-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-8-light);
  }
  &.zone_20s .piece-j {
    background-color: var(--c-light-10);
    border: .15rem solid var(--c-light-10);
  }
  &.zone_20s .piece-j-bottom:after,
  &.zone_20s .piece-j-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-10-dark);
  }
  &.zone_20s .piece-j-top:after,
  &.zone_20s .piece-j-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-10-light);
  }
  &.zone_20s .piece-l {
    background-color: var(--c-light-16);
    border: .15rem solid var(--c-light-16);
  }
  &.zone_20s .piece-l-bottom:after,
  &.zone_20s .piece-l-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-16-dark);
  }
  &.zone_20s .piece-l-top:after,
  &.zone_20s .piece-l-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-16-light);
  }
  &.zone_20s .piece-o {
    background-color: var(--c-light-1);
    border: .15rem solid var(--c-light-1);
  }
  &.zone_20s .piece-o-bottom:after,
  &.zone_20s .piece-o-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-1-dark);
  }
  &.zone_20s .piece-o-top:after,
  &.zone_20s .piece-o-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-1-light);
  }
  &.zone_20s .piece-s {
    background-color: var(--c-light-15);
    border: .15rem solid var(--c-light-15);
  }
  &.zone_20s .piece-s-bottom:after,
  &.zone_20s .piece-s-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-15-dark);
  }
  &.zone_20s .piece-s-top:after,
  &.zone_20s .piece-s-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-15-light);
  }
  &.zone_20s .piece-t {
    background-color: var(--c-light-9);
    border: .15rem solid var(--c-light-9);
  }
  &.zone_20s .piece-t-bottom:after,
  &.zone_20s .piece-t-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-9-dark);
  }
  &.zone_20s .piece-t-top:after,
  &.zone_20s .piece-t-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-9-light);
  }
  &.zone_20s .piece-z {
    background-color: var(--c-light-11);
    border: .15rem solid var(--c-light-11);
  }
  &.zone_20s .piece-z-bottom:after,
  &.zone_20s .piece-z-top-bottom:after {
    bottom: -.15rem;
    background-color: var(--c-light-11-dark);
  }
  &.zone_20s .piece-z-top:after,
  &.zone_20s .piece-z-top-bottom:before {
    top: -.15rem;
    background-color: var(--c-light-11-light);
  }

  &.zone_20s .next-block .block-empty {
    border: none;
  }
  &.zone_20s .queue-wrapper {
    margin-top: .5rem;
    height: 8rem;

    ${
      media.tablet`
        margin-top: .4rem;
        height: 6.4rem;
      `
    }
  }

  &.zone_20s .game-board-wrapper {
    flex-direction: column;
    background-color: var(--c-light-5);
    border-radius: 1.5rem; 
  }

  .game-board-bar {
    display: none;
    height: 1.5rem;
  }

  .game-board-bar--top {
    border-top-left-radius: 1.5rem; 
    border-top-right-radius: 1.5rem; 
  }

  .game-board-bar--bottom {
    border-bottom-left-radius: 1.5rem; 
    border-bottom-right-radius: 1.5rem;
  }

  &.zone_20s .game-board-bar {
    display: block;
    background-color: var(--c-light-5);
  }

  &.zone_20s .next-block {
    width: 9rem;
    height: 10rem;

    ${
      media.tablet`
        width: 7rem;
        height: 8rem;
      `
    }
  }

  &.zone_20s .next-block .game-block {
    width: 1.3rem;
    height: 1.3rem;

    ${
      media.tablet`
        width: 1.1rem;
        height: 1.1rem;
      `
    }
  }

  &.zone_80s .piece-preview,
  &.zone_90s .piece-preview, 
  &.zone_20s .piece-preview {
    background-color: var(--c-light-3);
    border: .15rem solid var(--c-gray-3);
    //opacity: .3;
  }

  &.zone_20s .game-board {
    border-spacing: 0;
    border-collapse: initial;
    background-color: var(--c-white);
  }

  .bg-img-left,
  .bg-img-right,
  .green-line {
    position: absolute;
  }

  .green-line {
    left: calc(-50vw + 50%);
    width: 100vw;
    height: .2rem;
    background-color: var(--c-dark-green);
    z-index: 1;
  }

  &.zone_80s .bg-img-left,
  &.zone_80s .bg-img-right {
    width: 20rem;
    height: 20rem;
  
    ${
      media.desktopXL`
        width: 15rem;
        height: 15rem;
      `
    }
  }
  &.zone_80s .bg-img-left {
    left: -30%;
    transform: translate(-50%, 0);

    ${
      media.desktopXL`
        left: -40%;
      `
    }

    ${
      media.tablet`
        left: unset;
        right: -32%;
        transform: translate(50%, 0);
      `
    }
  }
  &.zone_80s .bg-img-right {
    right: -30%;
    transform: translate(50%, 0);

    ${
      media.desktopXL`
        right: -40%;
      `
    }

    ${
      media.tablet`
        right: unset;
        left: -32%;
        transform: translate(-50%, 0);
      `
    }
  }
  &.zone_80s .bg-img-left,
  &.zone_80s .bg-img-right,
  .green-line {
    bottom: 5rem;

    ${
      media.tablet`
        bottom: 0;
      `
    }
  }
  &.zone_90s .bg-img-left,
  &.zone_90s .bg-img-right {
    display: none;

    ${
      media.tablet`
        display: flex;
        bottom: 0;
      `
    }
  }

  &.zone_90s .bg-img-left {
    left: -10%;
    width: 5rem;
    transform: translate(-50%, 0);
  }
  &.zone_90s .bg-img-right {
    right: -10%;
    width: 3.5rem;
    bottom: 5rem;
    transform: translate(50%, 0);
  }
`
export default TetrisStyled;