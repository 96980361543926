import { UserRepository } from '../repositories';
import { UserID, UserInfo } from '../models/UserModel';

export interface UserService {
  getUserInfo(userId: number): Promise<UserInfo | null>;
  addUser(): Promise<UserID>;
  updateUser(): Promise<void>;
}

export class UserServiceImpl implements UserService {
  private userRepository: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  async getUserInfo(userId: number) {
    const res = await this.userRepository.getUserInfo(userId);
    const data = res.getData();
    return data ? data[0] : data;
  }
  async addUser(): Promise<UserID> {
    return await this.userRepository.addUser();
  }
  async updateUser(): Promise<void> {
    await this.userRepository.updateUser();
  }
}