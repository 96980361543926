import React from 'react';
import styled from 'styled-components';

const IconShare80sStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2.65px;}
`

export default function IconShare80s() {
  return (
      <IconShare80sStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 105.94"
      >
        <rect className="cls-1" x="21.62" y="28.78" width="71.59" height="48.37"/>
        <polyline className="cls-1" points="23.46 28.78 57.42 52.97 93.21 28.78"/>
      </IconShare80sStyled>
  );
}