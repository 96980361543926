import { LevelResult } from '../../models'
import { LevelProgress, LevelConfigMap, ProgressUpdate } from '../../models/GameModel'
import {
  ActionType,
  SetNextLevelAction,
  SetCurrentLevelAction,
  SetLevelResultAction,
  ResetStateAction,
  SetUserProgressAction,
  SetLevelsConfigAction,
  UpdateUserProgressAction,
} from './types'

export function setCurrentLevel(dto: number | null): SetCurrentLevelAction {
  return {
    type: ActionType.SET_CURRENT_LEVEL,
    payload: dto
  }
}

export function resetState(): ResetStateAction {
  return {
    type: ActionType.RESET_STATE,
    payload: null
  }
}

export function setNextLevel(dto: number): SetNextLevelAction {
  return {
    type: ActionType.SET_NEXT_LEVEL,
    payload: dto
  }
}

export function setLevelResult(dto: LevelResult | null): SetLevelResultAction {
  return {
    type: ActionType.SET_LEVEL_RESULT,
    payload: dto
  }
}

export function setUserProgress(dto: LevelProgress): SetUserProgressAction {
  return {
    type: ActionType.SET_USER_PROGRESS,
    payload: dto
  }
}

export function setLevelsConfig(dto: LevelConfigMap): SetLevelsConfigAction {
  return {
    type: ActionType.SET_LEVELS_CONFIG,
    payload: dto
  }
}

export function updateUserProgress(dto: ProgressUpdate): UpdateUserProgressAction {
  return {
    type: ActionType.UPDATE_USER_PROGRESS,
    payload: dto
  }
}