import { Resolution, Zone } from '../models';
import IconLeaderboard80s from '../components/icons/IconLeaderBoard80s';
import IconLeaderboard90s from '../components/icons/IconLeaderBoard90s';
import IconLeaderboardModern from '../components/icons/IconLeaderBoardModern';
import IconReplay80s from '../components/icons/IconReplay80s';
import IconReplay90s from '../components/icons/IconReplay90s';
import IconReplayModern from '../components/icons/IconReplayModern';
import IconShare80s from '../components/icons/IconShare80s';
import IconShare90s from '../components/icons/IconShare90s';
import IconShareModern from '../components/icons/IconShareModern';

const levels = [1, 2, 3, 4, 5, 6, 7, 8];

const ZoneYears = {
  [Zone.ZONE_80s]: 1985,
  [Zone.ZONE_90s]: 1990,
  [Zone.ZONE_20s]: 2020,
}

const AntiSwipeResolutions = [
  Resolution.DESKTOP_L,
  Resolution.DESKTOP_XL,
]

const ZoneBtnIcons = [
  {
    [Zone.ZONE_80s]: IconShare80s,
    [Zone.ZONE_90s]: IconShare90s,
    [Zone.ZONE_20s]: IconShareModern,
  },
  {
    [Zone.ZONE_80s]: IconLeaderboard80s,
    [Zone.ZONE_90s]: IconLeaderboard90s,
    [Zone.ZONE_20s]: IconLeaderboardModern,
  },
  {
    [Zone.ZONE_80s]: IconReplay80s,
    [Zone.ZONE_90s]: IconReplay90s,
    [Zone.ZONE_20s]: IconReplayModern,
  },
]

export {
  levels,
  ZoneYears,
  AntiSwipeResolutions,
  ZoneBtnIcons
}