import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { media } from '../../helpers';
import { GameModalProps } from '../../models';

const GameModalStyled = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  & .modal-wrapper {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .6;
  }

  & .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    height: 8rem;
    width: 100%;
  }

  &.zone_80s  {
    top: -.3rem;
    left: -.3rem;
    width: calc(100% + .6rem);
    height: calc(100% + .6rem);
  }

  &.zone_20s .modal-wrapper {
    border-radius: .8rem;
  }

  &.zone_80s .modal-content {
    background-color: var(--c-80s);
    color: var(--c-black);
    font-size: 3rem;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.mainFont};

    ${
      media.tablet`
        font-size: 2.6rem;
      `
    }
  }
  &.zone_90s .modal-content {
    background-color: var(--c-black);
    color: var(--c-white);
    font-size: 1.5rem;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.numbersFont};

    ${
      media.tablet`
        font-size: 1.2rem;
      `
    }
  }
  &.zone_20s .modal-content {
    background-color: var(--c-light-4);
    color: var(--c-white);
    font-size: 2.5rem;

    ${
      media.tablet`
        font-size: 2.1xsrem;
      `
    }
  }
`

export default function GameModal({className, hide, text}: GameModalProps) {
  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      hide()
    }
  }, [hide]);

  const closeListener = useCallback(() => {
    hide()
  }, [hide])

  useEffect(() => {
    document.addEventListener('keyup', escapeListener);
    document.addEventListener('click', closeListener);
    return () => {
      document.removeEventListener('keyup', escapeListener);
      document.removeEventListener('click', closeListener);
    }
  }, [escapeListener, closeListener])

  useEffect(() => {
    const id = setTimeout(() => closeListener(), 1500);
    return () => {
      if (id) {
        clearInterval(id);
      }
    }
  }, [closeListener])

  return (
    <GameModalStyled className={className}>
      <div className="modal-wrapper"></div>
      <div
        onClick={e => e.stopPropagation()}
        className="modal-content"
      >
        { text }
      </div>
    </GameModalStyled>
  );
}
