import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import GamePage from "./game-page/GamePage";
import StartPage from "./start-page/StartPage";
import LevelMapPage from "./level-map/LevelMapPage";
import { useSelector } from 'react-redux';
import { getCurrentLevel } from '../store/game/selectors';

function MainRouter() {
  const level = useSelector(getCurrentLevel);
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/level/:id.html"
          render={() => {
            return level ? <GamePage /> : <Redirect to="/level-map.html" />
          }}
        />
        <Route path="/level-map.html">
          <LevelMapPage />
        </Route>
        <Route exact path="/index.html">
          <StartPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default MainRouter;