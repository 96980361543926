import React from 'react';
import styled from 'styled-components';

const IconLeaderBoard90sStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:#ede7b7;}
  .cls-2{fill:#f4ee31;}
  .cls-3{fill:#c4bc23;}
  .cls-4{fill:#0c0c0c;}
`

export default function IconLeaderBoard90s() {
  return (
      <IconLeaderBoard90sStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 114.7"
      >
        <polygon
          className="cls-1"
          points="104.06 16.46 104.06 21.95 104.06 27.45 104.06 32.93 104.06 38.41 104.06 43.91 104.06 49.4 104.06 54.88 104.06 60.38 104.06 65.86 104.06 71.34 104.06 76.84 104.06 82.33 104.06 87.81 104.06 93.31 104.06 98.79 109.55 98.79 109.55 93.31 109.55 87.81 109.55 82.33 109.55 76.84 109.55 71.34 109.55 65.86 109.55 60.38 109.55 54.88 109.55 49.4 109.55 43.91 109.55 38.41 109.55 32.93 109.55 27.45 109.55 21.95 109.55 16.46 104.06 16.46"
        />
        <rect
          className="cls-1"
          x="98.58" y="10.98" width="5.48" height="5.48"
        />
        <polygon
          className="cls-1"
          points="93.08 5.48 87.6 5.48 82.11 5.48 76.61 5.48 71.13 5.48 65.65 5.48 60.15 5.48 54.67 5.48 49.18 5.48 43.69 5.48 38.2 5.48 32.72 5.48 27.22 5.48 21.74 5.48 16.25 5.48 16.25 10.98 21.74 10.98 27.22 10.98 32.72 10.98 38.2 10.98 43.69 10.98 49.18 10.98 54.67 10.98 60.15 10.98 65.65 10.98 71.13 10.98 76.61 10.98 82.11 10.98 87.6 10.98 93.08 10.98 98.58 10.98 98.58 5.48 93.08 5.48"
        />
        <polygon
          className="cls-2"
          points="98.58 16.46 98.58 10.98 93.08 10.98 87.6 10.98 82.11 10.98 76.61 10.98 71.13 10.98 65.65 10.98 60.15 10.98 54.67 10.98 49.18 10.98 43.69 10.98 38.2 10.98 32.72 10.98 27.22 10.98 21.74 10.98 16.25 10.98 10.76 10.98 10.76 16.46 10.76 21.95 10.76 27.45 10.76 32.93 10.76 38.41 10.76 43.91 10.76 49.4 10.76 54.88 10.76 60.38 10.76 65.86 10.76 71.34 10.76 76.84 10.76 82.33 10.76 87.81 10.76 93.31 10.76 98.79 16.25 98.79 16.25 104.27 21.74 104.27 27.22 104.27 32.72 104.27 38.2 104.27 43.69 104.27 49.18 104.27 54.67 104.27 60.15 104.27 65.65 104.27 71.13 104.27 76.61 104.27 82.11 104.27 87.6 104.27 93.08 104.27 98.58 104.27 104.06 104.27 104.06 98.79 104.06 93.31 104.06 87.81 104.06 82.33 104.06 76.84 104.06 71.34 104.06 65.86 104.06 60.38 104.06 54.88 104.06 49.4 104.06 43.91 104.06 38.41 104.06 32.93 104.06 27.45 104.06 21.95 104.06 16.46 98.58 16.46"
        />
        <polygon
          className="cls-3"
          points="93.08 104.27 87.6 104.27 82.11 104.27 76.61 104.27 71.13 104.27 65.65 104.27 60.15 104.27 54.67 104.27 49.18 104.27 43.69 104.27 38.2 104.27 32.72 104.27 27.22 104.27 21.74 104.27 16.25 104.27 16.25 109.77 21.74 109.77 27.22 109.77 32.72 109.77 38.2 109.77 43.69 109.77 49.18 109.77 54.67 109.77 60.15 109.77 65.65 109.77 71.13 109.77 76.61 109.77 82.11 109.77 87.6 109.77 93.08 109.77 98.58 109.77 98.58 104.27 93.08 104.27"
        />
        <rect
          className="cls-3"
          x="10.75" y="98.79" width="5.5" height="5.48"
        />
        <polygon
          className="cls-3"
          points="10.76 87.81 10.76 82.33 10.76 76.84 10.76 71.34 10.76 65.86 10.76 60.38 10.76 54.88 10.76 49.4 10.76 43.91 10.76 38.41 10.76 32.93 10.76 27.45 10.76 21.95 10.76 16.46 5.27 16.46 5.27 21.95 5.27 27.45 5.27 32.93 5.27 38.41 5.27 43.91 5.27 49.4 5.27 54.88 5.27 60.38 5.27 65.86 5.27 71.34 5.27 76.84 5.27 82.33 5.27 87.81 5.27 93.31 5.27 98.79 10.76 98.79 10.76 93.31 10.76 87.81"
        />
        <polygon
          className="cls-4"
          points="109.55 16.46 109.55 21.95 109.55 27.45 109.55 32.93 109.55 38.41 109.55 43.91 109.55 49.4 109.55 54.88 109.55 60.38 109.55 65.86 109.55 71.34 109.55 76.84 109.55 82.33 109.55 87.81 109.55 93.31 109.55 98.79 115.05 98.79 115.05 93.31 115.05 87.81 115.05 82.33 115.05 76.84 115.05 71.34 115.05 65.86 115.05 60.38 115.05 54.88 115.05 49.4 115.05 43.91 115.05 38.41 115.05 32.93 115.05 27.45 115.05 21.95 115.05 16.46 109.55 16.46"
        />
        <rect
          className="cls-4"
          x="104.06" y="98.79" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="104.06" y="10.98" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="98.58" y="104.27" width="5.48" height="5.5"
        />
        <rect
          className="cls-4"
          x="98.58" y="5.48" width="5.48" height="5.5"
        />
        <polygon
          className="cls-4"
          points="87.6 109.77 82.11 109.77 76.61 109.77 71.13 109.77 65.65 109.77 60.15 109.77 54.67 109.77 49.18 109.77 43.69 109.77 38.2 109.77 32.72 109.77 27.22 109.77 21.74 109.77 16.25 109.77 16.25 115.26 21.74 115.26 27.22 115.26 32.72 115.26 38.2 115.26 43.69 115.26 49.18 115.26 54.67 115.26 60.15 115.26 65.65 115.26 71.13 115.26 76.61 115.26 82.11 115.26 87.6 115.26 93.08 115.26 98.58 115.26 98.58 109.77 93.08 109.77 87.6 109.77"
        />
        <polygon
          className="cls-4"
          points="27.22 5.48 32.72 5.48 38.2 5.48 43.69 5.48 49.18 5.48 54.67 5.48 60.15 5.48 65.65 5.48 71.13 5.48 76.61 5.48 82.11 5.48 87.6 5.48 93.08 5.48 98.58 5.48 98.58 0 93.08 0 87.6 0 82.11 0 76.61 0 71.13 0 65.65 0 60.15 0 54.67 0 49.18 0 43.69 0 38.2 0 32.72 0 27.22 0 21.74 0 16.25 0 16.25 5.48 21.74 5.48 27.22 5.48"
        />
        <rect
          className="cls-4"
          x="10.75" y="104.27" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="10.75" y="5.48" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="5.27" y="98.79" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="5.27" y="10.98" width="5.48" height="5.48"
        />
        <polygon
          className="cls-4"
          points="5.27 87.81 5.27 82.33 5.27 76.84 5.27 71.34 5.27 65.86 5.27 60.38 5.27 54.88 5.27 49.4 5.27 43.91 5.27 38.41 5.27 32.93 5.27 27.45 5.27 21.95 5.27 16.46 -0.21 16.46 -0.21 21.95 -0.21 27.45 -0.21 32.93 -0.21 38.41 -0.21 43.91 -0.21 49.4 -0.21 54.88 -0.21 60.38 -0.21 65.86 -0.21 71.34 -0.21 76.84 -0.21 82.33 -0.21 87.81 -0.21 93.31 -0.21 98.79 5.27 98.79 5.27 93.31 5.27 87.81"
        />
        <polygon
          className="cls-4"
          points="95.84 49.4 95.84 54.88 95.84 60.38 95.84 65.86 95.84 71.34 95.84 76.84 95.84 82.33 95.84 87.81 90.34 87.81 84.86 87.81 79.37 87.81 73.87 87.81 73.87 82.33 73.87 76.84 73.87 71.34 73.87 65.86 73.87 60.38 73.87 54.88 73.87 49.4 79.37 49.4 84.86 49.4 90.34 49.4 95.84 49.4 95.84 43.91 90.34 43.91 84.86 43.91 79.37 43.91 73.87 43.91 73.87 38.41 73.87 37.42 73.87 32.93 73.87 27.45 68.39 27.45 68.39 32.93 68.39 38.41 68.39 43.91 68.39 49.4 68.39 54.88 68.39 60.38 68.39 65.86 68.39 71.34 68.39 76.84 68.39 82.33 68.39 87.81 62.91 87.81 57.41 87.81 51.93 87.81 46.44 87.81 46.44 82.33 46.44 76.84 46.44 71.34 46.44 65.86 46.44 60.38 46.44 54.88 46.44 49.4 46.44 43.91 46.44 38.41 46.44 32.93 46.44 27.45 40.94 27.45 40.94 32.93 40.94 38.41 35.46 38.41 29.98 38.41 24.48 38.41 19 38.41 19 43.91 24.48 43.91 29.98 43.91 35.46 43.91 40.94 43.91 40.94 49.4 40.94 54.88 40.94 60.38 40.94 65.86 40.94 71.34 40.94 76.84 40.94 82.33 40.94 87.81 35.46 87.81 29.98 87.81 24.48 87.81 19 87.81 19 82.33 19 76.84 19 71.34 19 65.86 19 60.38 19 54.88 19 49.4 19 43.91 13.51 43.91 13.51 49.4 13.51 54.88 13.51 60.38 13.51 65.86 13.51 71.34 13.51 76.84 13.51 82.33 13.51 87.81 13.51 93.31 19 93.31 24.48 93.31 29.98 93.31 35.46 93.31 40.94 93.31 46.44 93.31 51.93 93.31 57.41 93.31 62.91 93.31 68.39 93.31 73.87 93.31 79.37 93.31 84.86 93.31 90.34 93.31 95.84 93.31 101.32 93.31 101.32 87.81 101.32 82.33 101.32 76.84 101.32 71.34 101.32 65.86 101.32 60.38 101.32 54.88 101.32 49.4 95.84 49.4"
        />
        <polygon
          className="cls-4"
          points="79.37 54.88 79.37 60.38 84.86 60.38 84.86 65.86 79.37 65.86 79.37 71.34 84.86 71.34 84.86 76.84 79.37 76.84 79.37 82.33 84.86 82.33 90.34 82.33 90.34 76.84 90.34 71.34 90.34 65.86 90.34 60.38 90.34 54.88 84.86 54.88 79.37 54.88"
        />
        <polygon
          className="cls-4"
          points="57.41 49.4 57.41 54.88 57.41 60.38 62.91 60.38 62.91 54.88 62.91 49.4 62.91 43.91 62.91 38.41 57.41 38.41 51.93 38.41 51.93 43.91 57.41 43.91 57.41 49.4"
        />
        <polygon
          className="cls-4"
          points="57.41 27.45 62.91 27.45 68.39 27.45 68.39 21.95 62.91 21.95 57.41 21.95 51.93 21.95 46.44 21.95 46.44 27.45 51.93 27.45 57.41 27.45"
        />
        <polygon
          className="cls-4"
          points="35.46 82.33 35.46 76.84 29.98 76.84 29.98 71.34 35.46 71.34 35.46 65.86 35.46 60.38 35.46 54.88 29.98 54.88 24.48 54.88 24.48 60.38 29.98 60.38 29.98 65.86 24.48 65.86 24.48 71.34 24.48 76.84 24.48 82.33 29.98 82.33 35.46 82.33"
        />
      </IconLeaderBoard90sStyled>
  );
}