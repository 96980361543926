export enum ActionType {
  SET_USER = '@@user/SET_USER',
}

export interface SetUserAction {
  type: ActionType.SET_USER;
  payload: UserState;
}

export interface UserState {
  nickname: string | null;
  team: string | null;
  userId: number | null;
}

export type BaseAction = SetUserAction;