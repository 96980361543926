import { LevelResult } from "../../models";
import { LevelProgress, LevelConfigMap, ProgressUpdate } from "../../models/GameModel";

export enum ActionType {
  SET_CURRENT_LEVEL = '@@user/SET_CURRENT_LEVEL',
  SET_NEXT_LEVEL = '@@user/SET_NEXT_LEVEL',
  SET_LEVEL_RESULT = '@@user/SET_LEVEL_RESULT',
  RESET_STATE = '@@user/RESET_STATE',
  SET_USER_PROGRESS = '@@user/SET_USER_PROGRESS',
  SET_LEVELS_CONFIG = '@@user/SET_LEVELS_CONFIG',
  UPDATE_USER_PROGRESS = '@@user/UPDATE_USER_PROGRESS',
}

export interface SetCurrentLevelAction {
  type: ActionType.SET_CURRENT_LEVEL;
  payload: number | null;
}

export interface SetNextLevelAction {
  type: ActionType.SET_NEXT_LEVEL;
  payload: number;
}

export interface SetLevelResultAction {
  type: ActionType.SET_LEVEL_RESULT;
  payload: LevelResult | null;
}

export interface ResetStateAction {
  type: ActionType.RESET_STATE;
  payload: null;
}

export interface SetUserProgressAction {
  type: ActionType.SET_USER_PROGRESS;
  payload: LevelProgress;
}

export interface SetLevelsConfigAction {
  type: ActionType.SET_LEVELS_CONFIG;
  payload: LevelConfigMap;
}

export interface UpdateUserProgressAction {
  type: ActionType.UPDATE_USER_PROGRESS;
  payload: ProgressUpdate;
}

export interface GameState {
  currentLevel: number | null;
  levelsConfig: LevelConfigMap | null;
  nextLevel: number;
  levelResult: LevelResult | null;
  score: number;
  progress: null | LevelProgress;
}

export interface UserInfo {
  
}

export type BaseAction = SetNextLevelAction
  | SetCurrentLevelAction
  | SetLevelResultAction
  | ResetStateAction
  | SetUserProgressAction
  | SetLevelsConfigAction
  | UpdateUserProgressAction;