import React from 'react';
import styled from 'styled-components';

const IconLeaderBoardModernStyled = styled.svg`
  height: 100%;
  width: 100%;
`

export default function IconLeaderBoardModern() {
  return (
      <IconLeaderBoardModernStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 105.94"
      >
        <path d="M69,54.52V23.75H45.65V40.58H24.5v41.8h65V54.52Zm-24,25H27.39v-36H45Zm21.15,0H48.54V26.63H66.11Zm20.53,0H69.07V57.4H86.64Z"/>
        <path d="M56.63,42.89V34.38l-2.52,1V34.26l3-2.17H58.5v10.8Z"/>
        <path d="M32.69,58V56.73l2.88-3.14c.55-.6,1-1.11,1.31-1.52a5.44,5.44,0,0,0,.7-1.06,2,2,0,0,0,.22-.84q0-1.59-1.92-1.59a6,6,0,0,0-2.86.84V47.81a6,6,0,0,1,1.48-.6A6.53,6.53,0,0,1,36.11,47a3.88,3.88,0,0,1,2.6.79A2.78,2.78,0,0,1,39.63,50a3.14,3.14,0,0,1-.26,1.21,7.29,7.29,0,0,1-.84,1.38A22.5,22.5,0,0,1,37,54.42l-1.87,2h4.52V58Z"/>
        <path d="M74.21,70.69v-1.6a6.12,6.12,0,0,0,1.4.51,6.81,6.81,0,0,0,1.54.18A2.8,2.8,0,0,0,79,69.24a1.85,1.85,0,0,0,.65-1.5,1.78,1.78,0,0,0-.52-1.35,2,2,0,0,0-1.46-.49,4.5,4.5,0,0,0-.83.07,5,5,0,0,0-.79.23l-.62-1,3.1-3.26H74.36V60.37H80.9v1.2L78,64.62a3.62,3.62,0,0,1,2.48.89,3,3,0,0,1,.89,2.24,3.35,3.35,0,0,1-1.09,2.65,4.41,4.41,0,0,1-3,1,7.3,7.3,0,0,1-1.64-.17A5.19,5.19,0,0,1,74.21,70.69Z"/>
      </IconLeaderBoardModernStyled>
  );
}