import { BaseAction, ActionType, UserState } from "./types";

const initialState: UserState = {
  team: null,
  userId: null,
  nickname: null,
};

export default function user(
  state = initialState,
  action: BaseAction,
): UserState {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        userId: action.payload.userId,
        team: action.payload.team,
        nickname: action.payload.nickname,
      };
    default:
      return state;
  }
}