import React, { useEffect, useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import level_map_bg from '../../assets/graphics/saga-map/saga_background@3x.png';
import LevelMapItem from './LevelMapItem';
import { levels } from '../../data';
import BaseImg from '../ui/BaseImg';
import { isDeviceMobile } from '../../helpers';
import { GameStatus, LevelStatus } from '../../models';
import { Modal } from '../ui/Modal';
import LevelResult from './LevelResult';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLevelResult,
  getCurrentLevel,
  getPlayedLevelProgress,
} from '../../store/game/selectors';
import { getUserId } from '../../store/user/selectors';
import { setLevelResult, setCurrentLevel, updateUserProgress, resetState } from '../../store/game/actions';
import LevelDescriptionModal from '../LevelDescriptionModal';
import ServiceContext from '../../context/serviceContext';
import { ProgressUpdateDTO, ProgressUpdate } from '../../models/GameModel';

const LevelMapPageStyled = styled.div`
  width: 100%;
  overflow: auto;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }
`

function defineGameStatus(playedLevel: number, status: GameStatus) {
  if (status === GameStatus.LOST) return LevelStatus.GAME_OVER;
  return playedLevel === 8
    ? LevelStatus.GAME_COMPLETE
    : LevelStatus.LEVEL_COMPLETE;
}

export default function LevelMapPage() {
  const [levelStatus, setLevelStatus] = useState<LevelStatus | null>(null);
  const [isNextLevelModal, setIsNextLevelModal] = useState(false);
  const isMobile = isDeviceMobile();
  const levelResult = useSelector(getLevelResult);
  const playedLevel = useSelector(getCurrentLevel);
  const levelProgress = useSelector(getPlayedLevelProgress);
  const userId = useSelector(getUserId);
  const { gameService } = useContext(ServiceContext);
  const dispatch = useDispatch();

  const sendProgress = useCallback(async (score: number, playedLevel: number) => {
    if (userId) {
      const storeUpdate: ProgressUpdate = {
        level_id: playedLevel,
        score
      }
      const update: ProgressUpdateDTO = {
        user_id: userId,
        ...storeUpdate
      }
      if (typeof levelProgress === 'number') {
        if (score > levelProgress) {
          await gameService.updateProgress(update);
        }
      } else {
        await gameService.addProgress(update);
      }
      dispatch(updateUserProgress(storeUpdate));
    }
  }, [userId, levelProgress, gameService, dispatch])

  useEffect(() => {
    if (levelResult && playedLevel) {
      const status = defineGameStatus(playedLevel, levelResult.status)
      setLevelStatus(status);
      sendProgress(levelResult.score, playedLevel);
    }
  }, [levelResult, playedLevel, sendProgress])

  const closeModal = async () => {
    if (!!levelStatus) {
      if (levelStatus === LevelStatus.GAME_OVER || levelStatus === LevelStatus.GAME_COMPLETE) {
        dispatch(resetState());
      } else {
        clearPrevLevelResult();
      }
      setLevelStatus(null);
    } else {
      setIsNextLevelModal(false);
    }
  }
   
  const clearPrevLevelResult = () => {
    dispatch(setLevelResult(null));
    dispatch(setCurrentLevel(null));
  }

  const openLevelModal = async () => {
    setIsNextLevelModal(true);
  }

  return (
    <LevelMapPageStyled>
      <BaseImg
        src={level_map_bg}
        alt="Level map"
      />
      {
        levels.reverse().map(level => {
          return (
            <LevelMapItem
              key={level}
              level={level}
              isMobile={isMobile}
              open={openLevelModal}
            />
          )
        })
      }
      <Modal
        hide={closeModal}
        visible={!!levelStatus || isNextLevelModal}
      >
        { levelStatus && playedLevel &&
          <LevelResult
            status={levelStatus}
            level={playedLevel}
            close={closeModal}
          />
        }
        { isNextLevelModal && <LevelDescriptionModal /> }
      </Modal>
    </LevelMapPageStyled>
  );
}