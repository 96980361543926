import React, { useEffect, useState } from 'react';
import { TimerProps, Zone } from '../../models';
import box_png_3x from '../../assets/graphics/90s/text-box/90s_text_box@3x.png';
import box_png_2x from '../../assets/graphics/90s/text-box/90s_text_box@2x.png';
import box_png from '../../assets/graphics/90s/text-box/90s_text_box.png';
import BaseImg from '../ui/BaseImg';
import IconClock from '../icons/IconClock';
import TimerStyled from '../ui/TimerStyled';
import { getSrcset } from '../../helpers';

export default function Timer({time, isPaused, timeEndHandler, zone}: TimerProps) {
  const [totalLeftTime, setTotalLeftTime] = useState(time);
  const [minutes, setMinutes] = useState(Math.floor(time / 60));
  const [seconds, setSeconds] = useState((Math.floor(time % 60)));
  const srcSet = getSrcset([box_png, box_png_2x, box_png_3x]);

  useEffect(() => {
    const id = setTimeout(() => updateSeconds(), 1000);
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  })

  const updateSeconds = () => {
    if (totalLeftTime === 0) {
      timeEndHandler(true);
      return;
    };
    if (isPaused) return;
    const newLeftTime = totalLeftTime - 1 < 0 ? 0 : totalLeftTime - 1;
    const newMinutes = Math.floor(newLeftTime / 60);
    const newSeconds = Math.floor(newLeftTime % 60);
    if (newMinutes !== minutes) {
      setMinutes(newMinutes);
    }
    setSeconds(newSeconds);
    setTotalLeftTime(newLeftTime);
  }

  return (
    <TimerStyled className={zone.toLowerCase()}>
      {
        zone === Zone.ZONE_90s
        && <BaseImg
          src={box_png_3x}
          srcset={srcSet}
          alt="Timer image"
        />
      }
      {
        zone === Zone.ZONE_20s && <IconClock />
      }
      <span>{minutes}</span><span className="separator">:</span><span>{seconds < 10 ? `0${seconds}` : seconds }</span>
    </TimerStyled>
  );
}