import React, { useEffect, useContext } from 'react';
import { defineParamDependOnLevel, defineNextZoneOnLevel } from '../../helpers';
import { useSelector } from 'react-redux';
import { getNextLevel } from '../../store/game/selectors';
import lock_green_3x from '../../assets/graphics/saga-map/icon_lock/lock_green@3x.png';
import lock_black_3x from '../../assets/graphics/saga-map/icon_lock/lock_black@3x.png';
import check_white_3x from '../../assets/graphics/saga-map/icon_check/check_white@3x.png';
import check_green_3x from '../../assets/graphics/saga-map/icon_check/check_green@3x.png';
import check_black_3x from '../../assets/graphics/saga-map/icon_check/check_black@3x.png';
import BaseImg from '../ui/BaseImg';
import { LevelMapItemProps } from '../../models';
import {
  DesktopLevelMapItemStyled,
  MobileLevelMapItemStyled
} from '../ui/LevelMapItemStyled';
import ThemeContext from '../../context/themeContext';

const lockList = [
  lock_green_3x,
  lock_black_3x,
  lock_black_3x 
]

const checkList = [
  check_green_3x,
  check_black_3x,
  check_white_3x 
]

const LevelMapItem = ({
  level,
  isMobile = false,
  open
}: LevelMapItemProps) => {
  const levelName = `Level ${level}`;
  const icon_lock = defineParamDependOnLevel(level, lockList);
  const check_lock = defineParamDependOnLevel(level, checkList);
  const Component = isMobile ? MobileLevelMapItemStyled : DesktopLevelMapItemStyled;
  const nextLevel = useSelector(getNextLevel);
  const isClosed = level > nextLevel;
  const isCompleted = level < nextLevel;
  const { changeZone } = useContext(ThemeContext);

  const handleClick = async () => {
    if (isCompleted || isClosed) return;
    const nextZone = defineNextZoneOnLevel(level);
    changeZone(nextZone);
    open(level);
  }

  useEffect(() => {
    let id: null | NodeJS.Timer = null
    if (nextLevel === level) {
      id = setTimeout(() => {
        const elem = document.getElementById(`level-${level}`);
        if (elem) {
          elem.scrollIntoView({
            block: 'center',
            inline: 'center',
          });
        }
      }, 700);
    }
    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [nextLevel, level])

  return (
    <Component
      className={`${isMobile ? 'mobile' : 'desktop'} level-${level}`}
      id={`level-${level}`}
      onClick={() => handleClick()}
    >
      <div className="title">{levelName}</div>
      <div className="description">
        {
          isClosed &&
          <div className="icon-wrapper">
            <BaseImg
              src={icon_lock}
              alt="Level closed"
            />
          </div>
        }
        {
          isCompleted &&
          <div className="icon-wrapper">
            <BaseImg
              src={check_lock}
              alt="Level completed"
            />
          </div>
        }
        {
          !isClosed && !isCompleted && <p>Play</p>
        }
      </div>
    </Component>
  );
}

export default LevelMapItem