import React, { useContext, useEffect } from 'react';
import { defineStatus } from '../../helpers';
import { LevelResultProps, LevelStatus } from '../../models';
import BaseButton from '../ui/BaseButton';
import { useSelector } from 'react-redux';
import {
  getTotalScore,
  isNewBestScore
} from '../../store/game/selectors';
import ZonedWrapper from '../ui/ZonedWrapper';
import { ZoneBtnIcons } from '../../data';
import LevelResultStyled from '../ui/LevelResultStyled';
import ThemeContext from '../../context/themeContext';

export default function LevelResult({status, level, close}: LevelResultProps) {
  const [title_1, title_2] = defineStatus(status, level);
  const score = useSelector(getTotalScore);
  const { zone } = useContext(ThemeContext);
  const isBestScore = useSelector(isNewBestScore);
  const titlePart_1Class = status === LevelStatus.LEVEL_COMPLETE
    ? 'title__part-1'
    : status === LevelStatus.GAME_COMPLETE
      ? 'title--default'
      : 'title--big';
  const titlePart_2Class = status === LevelStatus.LEVEL_COMPLETE
    ? 'title__part-2'
    : status === LevelStatus.GAME_COMPLETE
      ? 'title--default'
      : 'title--big';
  const badgeClass = `badge ${zone.toLowerCase()} ${isBestScore ? 'visible' : 'hidden'}`

  useEffect(() => {
    if (status === LevelStatus.GAME_OVER) {

    }
  }, [status])

  const restartGame = () => {
    close();
  }
  const sendEmail = () => {
    console.log('send email');
    close();
  }
  const goToLeaderboard = () => {
    console.log('go To Leaderboard');
    close();
  }
  const handlers = [
    sendEmail,
    goToLeaderboard,
    restartGame
  ]

  return (
    <ZonedWrapper zone={zone}>
      <LevelResultStyled className={zone.toLowerCase()}>
        <p className={titlePart_1Class}>{ title_1 }</p>
        <p className={titlePart_2Class}>{ title_2 }</p>
        <div className={badgeClass}>New best score!</div>
        <p className="score-title">
          {
            status === LevelStatus.LEVEL_COMPLETE
              ? 'CURRENT SCORE:'
              : 'FINAL SCORE:'
          }
        </p>
        <p className="score">{ score }</p>
        {
            status === LevelStatus.LEVEL_COMPLETE
              ? <BaseButton
                  handler={close}
                  className="continue-btn"
                >
                  Continue
                </BaseButton>
              : <div className="button-list">{
                ZoneBtnIcons.map((iconConfig, index) => {
                  const Component = iconConfig[zone];
                  return (
                    <BaseButton
                      handler={handlers[index]}
                      key={index}
                      className="control-btn"
                    >
                      <Component />
                    </BaseButton>
                  )
                })
              }
              </div>
          }
      </LevelResultStyled>
    </ZonedWrapper>
  );
}