import React from 'react';
import styled from 'styled-components';
import { IconColumnTopperProps } from '../../models';

const IconColumnTopperStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:#fff;}
  .cls-2{fill:#827223;}
  .cls-3{fill:#c9ba23;}
  .cls-4{fill:#f9f8d7;}
  .cls-5{fill:#f7e52d;}
  .cls-6{fill:#020107;}
`

export default function IconColumnTopper({className}: IconColumnTopperProps) {
  return (
      <IconColumnTopperStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 65.86 66.13"
        className={className}
      >
        <polygon
          className="cls-1"
          points="37.63 25.2 34.5 25.2 31.36 25.2 28.22 25.2 28.22 28.34 25.09 28.34 25.09 31.49 25.09 34.64 28.22 34.64 28.22 37.79 31.36 37.79 34.5 37.79 37.63 37.79 37.63 34.64 40.77 34.64 40.77 31.49 40.77 28.34 37.63 28.34 37.63 25.2"
        />
        <polygon
          className="cls-2"
          points="56.45 53.53 53.32 53.53 53.32 50.38 50.17 50.38 50.17 47.23 47.04 47.23 47.04 44.09 43.91 44.09 43.91 40.93 40.77 40.93 40.77 37.79 37.63 37.79 34.5 37.79 31.36 37.79 28.22 37.79 25.09 37.79 25.09 40.93 21.95 40.93 21.95 44.09 18.82 44.09 18.82 47.23 15.68 47.23 15.68 50.38 12.54 50.38 12.54 53.53 9.41 53.53 9.41 56.68 6.28 56.68 6.28 59.83 9.41 59.83 12.54 59.83 15.68 59.83 18.82 59.83 21.95 59.83 25.09 59.83 28.22 59.83 31.36 59.83 34.5 59.83 37.63 59.83 40.77 59.83 43.91 59.83 47.04 59.83 50.17 59.83 53.32 59.83 56.45 59.83 59.58 59.83 59.58 56.68 56.45 56.68 56.45 53.53"
        />
        <rect
          className="cls-3"
          x="56.45" y="6.3" width="3.13" height="3.15"
        />
        <polygon
          className="cls-3"
          points="53.32 59.83 50.17 59.83 47.04 59.83 43.91 59.83 40.77 59.83 37.63 59.83 34.5 59.83 31.36 59.83 28.22 59.83 25.09 59.83 21.95 59.83 18.82 59.83 15.68 59.83 12.54 59.83 9.41 59.83 6.28 59.83 6.28 62.98 9.41 62.98 12.54 62.98 15.68 62.98 18.82 62.98 21.95 62.98 25.09 62.98 28.22 62.98 31.36 62.98 34.5 62.98 37.63 62.98 40.77 62.98 43.91 62.98 47.04 62.98 50.17 62.98 53.32 62.98 56.45 62.98 59.58 62.98 59.58 59.83 56.45 59.83 53.32 59.83"
        />
        <rect
          className="cls-3"
          x="53.32" y="9.45" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="50.17" y="12.59" width="3.14" height="3.16"
        />
        <rect
          className="cls-3"
          x="47.04" y="15.75" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="43.91" y="18.89" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="40.77" y="31.49" width="3.14" height="3.16"
        />
        <rect
          className="cls-3"
          x="40.77" y="22.04" width="3.14" height="3.16"
        />
        <rect
          className="cls-3"
          x="37.63" y="34.64" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="37.63" y="25.19" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="25.09" y="34.64" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="25.09" y="25.19" width="3.13" height="3.15"
        />
        <polygon
          className="cls-3"
          points="21.95 31.49 21.95 34.64 25.09 34.64 25.09 31.49 25.09 28.34 21.95 28.34 21.95 31.49"
        />
        <rect
          className="cls-3"
          x="21.95" y="22.04" width="3.14" height="3.16"
        />
        <rect
          className="cls-3"
          x="18.82" y="18.89" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="15.68" y="15.75" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="12.54" y="12.59" width="3.14" height="3.16"
        />
        <rect
          className="cls-3"
          x="9.41" y="9.45" width="3.13" height="3.15"
        />
        <rect
          className="cls-3"
          x="6.28" y="6.3" width="3.13" height="3.15"
        />
        <polygon
          className="cls-4"
          points="59.58 9.45 59.58 12.59 59.58 15.75 59.58 18.89 59.58 22.04 59.58 25.2 59.58 28.34 59.58 31.49 59.58 34.64 59.58 37.79 59.58 40.93 59.58 44.09 59.58 47.23 59.58 50.38 59.58 53.53 59.58 56.68 59.58 59.83 62.72 59.83 62.72 56.68 62.72 53.53 62.72 50.38 62.72 47.23 62.72 44.09 62.72 40.93 62.72 37.79 62.72 34.64 62.72 31.49 62.72 28.34 62.72 25.2 62.72 22.04 62.72 18.89 62.72 15.75 62.72 12.59 62.72 9.45 62.72 6.3 59.58 6.3 59.58 9.45"
        />
        <polygon
          className="cls-4"
          points="50.17 6.3 47.04 6.3 43.91 6.3 40.77 6.3 37.63 6.3 34.5 6.3 31.36 6.3 28.22 6.3 25.09 6.3 21.95 6.3 18.82 6.3 15.68 6.3 12.54 6.3 9.41 6.3 9.41 9.45 12.54 9.45 12.54 12.59 15.68 12.59 15.68 15.75 18.82 15.75 18.82 18.89 21.95 18.89 21.95 22.04 25.09 22.04 25.09 25.2 28.22 25.2 31.36 25.2 34.5 25.2 37.63 25.2 40.77 25.2 40.77 22.04 43.91 22.04 43.91 18.89 47.04 18.89 47.04 15.75 50.17 15.75 50.17 12.59 53.32 12.59 53.32 9.45 56.45 9.45 56.45 6.3 53.32 6.3 50.17 6.3"
        />
        <polygon
          className="cls-4"
          points="3.13 12.59 3.13 15.75 3.13 18.89 3.13 22.04 3.13 25.2 3.13 28.34 3.13 31.49 3.13 34.64 3.13 37.79 3.13 40.93 3.13 44.09 3.13 47.23 3.13 50.38 3.13 53.53 3.13 56.68 6.28 56.68 6.28 53.53 6.28 50.38 6.28 47.23 6.28 44.09 6.28 40.93 6.28 37.79 6.28 34.64 6.28 31.49 6.28 28.34 6.28 25.2 6.28 22.04 6.28 18.89 6.28 15.75 6.28 12.59 6.28 9.45 3.13 9.45 3.13 12.59"
        />
        <polygon
          className="cls-5"
          points="56.45 12.59 53.32 12.59 53.32 15.75 50.17 15.75 50.17 18.89 47.04 18.89 47.04 22.04 43.91 22.04 43.91 25.2 40.77 25.2 40.77 28.34 40.77 31.49 43.91 31.49 43.91 34.64 40.77 34.64 40.77 37.79 40.77 40.93 43.91 40.93 43.91 44.09 47.04 44.09 47.04 47.23 50.17 47.23 50.17 50.38 53.32 50.38 53.32 53.53 56.45 53.53 56.45 56.68 59.58 56.68 59.58 53.53 59.58 50.38 59.58 47.23 59.58 44.09 59.58 40.93 59.58 37.79 59.58 34.64 59.58 31.49 59.58 28.34 59.58 25.2 59.58 22.04 59.58 18.89 59.58 15.75 59.58 12.59 59.58 9.45 56.45 9.45 56.45 12.59"
        />
        <polygon
          className="cls-5"
          points="53.32 3.15 50.17 3.15 47.04 3.15 43.91 3.15 40.77 3.15 37.63 3.15 34.5 3.15 31.36 3.15 28.22 3.15 25.09 3.15 21.95 3.15 18.82 3.15 15.68 3.15 12.54 3.15 9.41 3.15 6.28 3.15 6.28 6.3 9.41 6.3 12.54 6.3 15.68 6.3 18.82 6.3 21.95 6.3 25.09 6.3 28.22 6.3 31.36 6.3 34.5 6.3 37.63 6.3 40.77 6.3 43.91 6.3 47.04 6.3 50.17 6.3 53.32 6.3 56.45 6.3 59.58 6.3 59.58 3.15 56.45 3.15 53.32 3.15"
        />
        <polygon
          className="cls-5"
          points="6.28 15.75 6.28 18.89 6.28 22.04 6.28 25.2 6.28 28.34 6.28 31.49 6.28 34.64 6.28 37.79 6.28 40.93 6.28 44.09 6.28 47.23 6.28 50.38 6.28 53.53 6.28 56.68 9.41 56.68 9.41 53.53 12.54 53.53 12.54 50.38 15.68 50.38 15.68 47.23 18.82 47.23 18.82 44.09 21.95 44.09 21.95 40.93 25.09 40.93 25.09 37.79 25.09 34.64 21.95 34.64 21.95 31.49 21.95 28.34 25.09 28.34 25.09 25.2 21.95 25.2 21.95 22.04 18.82 22.04 18.82 18.89 15.68 18.89 15.68 15.75 12.54 15.75 12.54 12.59 9.41 12.59 9.41 9.45 6.28 9.45 6.28 12.59 6.28 15.75"
        />
        <rect
          className="cls-5"
          x="3.13" y="56.68" width="3.14" height="3.15"
        />
        <rect
          className="cls-5"
          x="3.13" y="6.3" width="3.14" height="3.15"
        />
        <polygon
          className="cls-6"
          points="62.72 9.45 62.72 12.59 62.72 15.75 62.72 18.89 62.72 22.04 62.72 25.2 62.72 28.34 62.72 31.49 62.72 34.64 62.72 37.79 62.72 40.93 62.72 44.09 62.72 47.23 62.72 50.38 62.72 53.53 62.72 56.68 62.72 59.83 65.86 59.83 65.86 56.68 65.86 53.53 65.86 50.38 65.86 47.23 65.86 44.09 65.86 40.93 65.86 37.79 65.86 34.64 65.86 31.49 65.86 28.34 65.86 25.2 65.86 22.04 65.86 18.89 65.86 15.75 65.86 12.59 65.86 9.45 65.86 6.3 62.72 6.3 62.72 9.45"
        />
        <rect
          className="cls-6"
          x="59.58" y="59.83" width="3.14" height="3.16"
        />
        <rect
          className="cls-6"
          x="59.58" y="3.15" width="3.14" height="3.16"
        />
        <polygon
          className="cls-6"
          points="53.32 62.98 50.17 62.98 47.04 62.98 43.91 62.98 40.77 62.98 37.63 62.98 34.5 62.98 31.36 62.98 28.22 62.98 25.09 62.98 21.95 62.98 18.82 62.98 15.68 62.98 12.54 62.98 9.41 62.98 6.28 62.98 6.28 66.13 9.41 66.13 12.54 66.13 15.68 66.13 18.82 66.13 21.95 66.13 25.09 66.13 28.22 66.13 31.36 66.13 34.5 66.13 37.63 66.13 40.77 66.13 43.91 66.13 47.04 66.13 50.17 66.13 53.32 66.13 56.45 66.13 59.58 66.13 59.58 62.98 56.45 62.98 53.32 62.98"
        />
        <polygon
          className="cls-6"
          points="12.54 3.15 15.68 3.15 18.82 3.15 21.95 3.15 25.09 3.15 28.22 3.15 31.36 3.15 34.5 3.15 37.63 3.15 40.77 3.15 43.91 3.15 47.04 3.15 50.17 3.15 53.32 3.15 56.45 3.15 59.58 3.15 59.58 0 56.45 0 53.32 0 50.17 0 47.04 0 43.91 0 40.77 0 37.63 0 34.5 0 31.36 0 28.22 0 25.09 0 21.95 0 18.82 0 15.68 0 12.54 0 9.41 0 6.28 0 6.28 3.15 9.41 3.15 12.54 3.15"
        />
        <rect
          className="cls-6"
          x="3.13" y="59.83" width="3.14" height="3.16"
        />
        <rect
          className="cls-6"
          x="3.13" y="3.15" width="3.14" height="3.16"
        />
        <polygon
          className="cls-6"
          points="3.13 53.53 3.13 50.38 3.13 47.23 3.13 44.09 3.13 40.93 3.13 37.79 3.13 34.64 3.13 31.49 3.13 28.34 3.13 25.2 3.13 22.04 3.13 18.89 3.13 15.75 3.13 12.59 3.13 9.45 3.13 6.3 0 6.3 0 9.45 0 12.59 0 15.75 0 18.89 0 22.04 0 25.2 0 28.34 0 31.49 0 34.64 0 37.79 0 40.93 0 44.09 0 47.23 0 50.38 0 53.53 0 56.68 0 59.83 3.13 59.83 3.13 56.68 3.13 53.53"
        />
      </IconColumnTopperStyled>
  );
}