import React from 'react';
import styled from 'styled-components';

const IconLeaderBoard80sStyled = styled.svg`
  height: 100%;
  width: 100%;

  text {
    font-size: 19px;
    fill: #070707;
    font-family: PerfectDOSVGA437Win, "Perfect DOS VGA 437 Win";
  }
  rect {
    fill:none;
    stroke:#000;
    stroke-miterlimit:10;
    stroke-width:2.65px;
  }
`

export default function IconLeaderBoard80s() {
  return (
      <IconLeaderBoard80sStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 105.94"
      >
        <rect x="19.6" y="42.12" width="25.14" height="45.01"/>
        <rect x="44.74" y="18.6" width="25.14" height="68.53"/>
        <rect x="70.09" y="57.33" width="25.14" height="30.01"/>
        <text transform="translate(52.12 38.77)">1</text>
        <text transform="translate(26.17 61.07)">2</text>
        <text transform="translate(78.26 75.26)">3</text>
      </IconLeaderBoard80sStyled>
  );
}