import React, { useContext } from 'react';
import BaseButton from './ui/BaseButton';
import { useDispatch, useSelector } from 'react-redux';
import { getNextLevel, getPlayingLevelConfig } from '../store/game/selectors';
import { setCurrentLevel } from '../store/game/actions';
import { useHistory } from 'react-router';
import { Zone } from '../models';
import ZonedWrapper from './ui/ZonedWrapper';
import BaseImg from './ui/BaseImg';
import storyicon_80s_3x from '../assets/graphics/80s/story-icon/80s_storyicon@3x.png';
import storyicon_80s_2x from '../assets/graphics/80s/story-icon/80s_storyicon@2x.png';
import storyicon_80s from '../assets/graphics/80s/story-icon/80s_storyicon.png';
import storyicon_90s_3x from '../assets/graphics/90s/story-icon/90s_storyicon@3x.png';
import storyicon_90s_2x from '../assets/graphics/90s/story-icon/90s_storyicon@2x.png';
import storyicon_90s from '../assets/graphics/90s/story-icon/90s_storyicon.png';
import storyicon_20s_3x from '../assets/graphics/20s/story-icon/20s_storyicon@3x.png';
import storyicon_20s_2x from '../assets/graphics/20s/story-icon/20s_storyicon@2x.png';
import storyicon_20s from '../assets/graphics/20s/story-icon/20s_storyicon.png';
import { getSrcset } from '../helpers';
import LevelDescriptionModalStyled from './ui/LevelDescriptionModalStyled';
import ThemeContext from '../context/themeContext';

const images = {
  [Zone.ZONE_80s]: {
    src: storyicon_80s_3x,
    srcset: getSrcset([storyicon_80s, storyicon_80s_2x, storyicon_80s_3x])
  },
  [Zone.ZONE_90s]: {
    src: storyicon_90s_3x,
    srcset: getSrcset([storyicon_90s, storyicon_90s_2x, storyicon_90s_3x])
  },
  [Zone.ZONE_20s]: {
    src: storyicon_20s_3x,
    srcset: getSrcset([storyicon_20s, storyicon_20s_2x, storyicon_20s_3x])
  },
}

export default function BaseZoneModal() {
  const { push } = useHistory();
  const { zone } = useContext(ThemeContext);
  const nextLevel = useSelector(getNextLevel);
  const dispatch = useDispatch();
  const levelConfig = useSelector(getPlayingLevelConfig(nextLevel));
  const description = levelConfig
    ? typeof levelConfig.levelDescription === 'string'
      ? [levelConfig.levelDescription]
      : levelConfig.levelDescription
    : null;
  const image = images[zone];

  const startLevel = async () => {
    if (!levelConfig) {
      return;
    }
    dispatch(setCurrentLevel(nextLevel));
    push(`level/${nextLevel}.html`);
  }

  return (
    <ZonedWrapper zone={zone}>
      <LevelDescriptionModalStyled className={zone.toLowerCase()}>
        <div className="image">
          <BaseImg
            src={image.src}
            srcset={image.srcset}
            alt="Age preview"
          />
        </div>
        <p className="level">Level { nextLevel }</p>
        {
          description && description.map((text, index) => <p key={index} className="description">{ text }</p>)
        }
        <BaseButton handler={startLevel}>
          Continue
        </BaseButton>
      </LevelDescriptionModalStyled>
    </ZonedWrapper>
  )
}