import React from 'react';
import styled from 'styled-components';
import { media } from '../../helpers';
import BaseButton from '../ui/BaseButton';

const ChooseTeamStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5rem;
  color: var(--c-black);
  background-color: var(--c-white);
  border-radius: 2rem;
  border: .1rem solid var(--c-black);

  ${
    media.tablet`
      paddig: 2rem;
    `
  }

  & .title {
    max-width: 20rem;
  }

  & .button-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
  }

  & button {
    width: 20rem;
    height: 7rem;
    border-radius: 2rem;
    box-shadow: 0 .8rem #999;
  }

  button:active {
    box-shadow: 0 .4rem #999;
    transform: translateY(.4rem);
  }

  & button:not(:first-child) {
    margin-top: 1.5rem;
  }

  & .button-list button:first-child {
    background-color: var(--c-light-2);
  }

  & .button-list button:nth-child(2) {
    background-color: var(--c-gray-3);
  }

  & .button-list button:last-child {
    background-color: var(--c-dark-green);
  }
`

const teamList = [
  'Red',
  'Blue',
  'Yellow'
]

export default function ChooseTeam() {
  const chooseTeam = (team: string) => {
    console.log(team.toLowerCase());
  }
  return (
    <ChooseTeamStyled>
      <h2 className="title">Choose a team</h2>
      <div className="button-list">
        {
          teamList.map(team => {
            return (
              <BaseButton
                handler={() => chooseTeam(team)}
                key={team}
              >
                {`${team} team`}
              </BaseButton>
            )
          })
        }
      </div>
    </ChooseTeamStyled>
  );
}