import React from 'react';
import styled from 'styled-components';

const IconReplayModernStyled = styled.svg`
  height: 100%;
  width: 100%;
`

export default function IconReplayModern() {
  return (
      <IconReplayModernStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 105.94"
      >
        <path fill="#fff" d="M61.32,23.14a29.71,29.71,0,0,0-29.2,23.74L26.44,35l-2.61,1.24,8.71,18.24,15-11.28-1.73-2.31-11.2,8.4a27,27,0,1,1,6.88,21.95l-2.13,2a29.83,29.83,0,1,0,21.94-50Z"/>
      </IconReplayModernStyled>
  );
}