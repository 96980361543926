import styled from 'styled-components';
import { media } from '../../helpers';

const PointsBoardStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-items: baseline;
  font-family: ${({ theme }) => theme.numbersFont};
  grid-area: points;
  justify-self: flex-start;
  z-index: 2;

  ${
    media.tablet`
      align-items: center;
    `
  }

  &.zone_80s {
    min-width: 15rem;
    padding: 0 1rem;
    font-size: 4rem;
    background-color: var(--c-80s);
    color: var(--c-gray-2);
    margin-top: 2rem;

    ${
      media.tablet`
        min-width: 11rem;
        font-size: 3.5rem;
      `
    }

    ${
      media.mobileL`
        min-width: 9rem;
        font-size: 3rem;
      `
    }
  }

  &.zone_90s {
    color: var(--c-white);
    flex-direction: column;
    margin-top: 4rem;
    min-width: 15rem;
    font-size: 3.5rem;

    ${
      media.tablet`
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-width: 11rem;
        font-size: 2.5rem;
      `
    }

    ${
      media.mobileM`
        min-width: 10rem;
        font-size: 1.5rem;
      `
    }
  }

  &.zone_20s {
    font-size: 5rem;
    color: var(--c-white);
    justify-content: flex-start;
    font-size: 5rem;
    margin-top: 3rem;
    min-width: 14rem;

    ${
      media.tablet`
        margin-top: 1rem;
        font-size: 3rem;
        min-width: 11rem;
      `
    }
    ${
      media.mobileM`
        font-size: 2rem;
        min-width: 9rem;
      `
    }
  }
`

export default PointsBoardStyled;