import styled from 'styled-components';
import btn_bg_90s from '../../assets/graphics/90s/90s_button_rectangle.svg';
import { media } from '../../helpers';

const LevelResultStyled = styled.div`
  padding: 6rem 2rem 4rem;
  color: ${({ theme }) => theme.color};

  & .score {
    margin: 3rem 0 4rem;
    font-size: 
  }

  & .button-list {
    display: flex;
    align-items: center;
    justify-content: center;

    & button {
      height: 6rem;
      width: 6rem;
    }
  }

  & .button-list button:not(:first-child) {
    margin-left: 1rem;
  }

  .continue-btn {
    padding: 0 3rem;
    height: 6rem;
    font-size: 2rem;
    margin-top: 3rem;
  }

  .score-title {
    margin-top: 2rem;
    font-size: 1.3rem;
  }

  .badge {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    vertical-align: middle;
    padding: 0 1rem;
    margin-top: 2rem;
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
    height: 2rem;
  }

  &.zone_80s {
    background-color: var(--c-80s-dark);
    border: .2rem solid var(--c-80s);
    text-align: center;

    ${
      media.mobileM`
        padding: 4rem 2rem;
      `
    }

    & .continue-btn {
      width: 100%;
      border: .2rem solid var(--c-gray-1);
      background-color: var(--c-80s);
      color: var(--c-80s-dark);
      font-weight: bold;
    }

    & .control-btn {
      background-color: var(--c-80s);
    }

    & .badge {
      background-color: var(--c-80s);
      color: black;
    }
  
    & .badge,
    & .title--big,
    & .title--default,
    & .title__part-1,
    & .title__part-2 {
      text-transform: uppercase;
    }

    & .title__part-1 {
      margin-top: 3rem;
      font-size: 3rem;
    }
    & .title__part-2 {
      margin-top: 1rem;
      font-size: 4.8rem;
    }

    & .score  {
      font-size: 4rem;
    }

    & .title--big,
    & .title--default {
      margin-top: 2rem;
    }

    & .title--default {
      font-size: 5rem;
    }

    & .title--big {
      font-size: 6rem;
    }
  }

  &.zone_90s {
    text-align: center;
    padding: 8rem 2rem 4rem;
    font-family: ${({ theme }) => theme.numbersFont};

    ${
      media.mobileM`
        padding: 4rem 2rem;
      `
    }

    & .continue-btn {
      background-color: transparent;
      font-size: 1.5rem;
      background-image: url(${btn_bg_90s});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 3rem auto 0;
      padding: 0 2.5rem;
    }

    & .control-btn {
      background-color: transparent;
    }

    & .badge {
      background-color: var(--c-light-17);
      color: var(--c-white);
      font-size: .8rem;
    }
    & .badge,
    & .title--big,
    & .title--default,
    & .title__part-1,
    & .title__part-2 {
      text-transform: uppercase;
    }

    & .title__part-1 {
      margin-top: 3rem;
      font-size: 1.8rem;
    }
    & .title__part-2 {
      margin-top: 2rem;
      font-size: 2.5rem;
    }

    & .score  {
      font-size: 3rem;
    }

    & .score-title {
      font-size: 1rem;
    }
    & .title--big,
    & .title--default {
      margin-top: 2rem;
    }

    & .title--default {
      font-size: 3rem;
    }

    & .title--big {
      font-size: 4rem;
    }

    & .button-list button {
      height: 7rem;
      width: 7rem;
    }
  }

  &.zone_20s {
    text-align: center;
    padding: 8rem 2rem 4rem;
    font-family: ${({ theme }) => theme.numbersFont};
    font-weight: bold;

    ${
      media.mobileM`
        padding: 4rem 2rem;
      `
    }

    & .continue-btn {
      background-color: var(--c-light-2);
      font-size: 2rem;
      margin: 3rem auto 0;
      width: 20rem;
      border-radius: .8rem;
      font-weight: bold;
    }

    & .control-btn {
      background-color: var(--c-light-2);
      border-radius: .4rem;
    }

    & .control-btn:last-child {
      background-color: var(--c-light-11);
    }

    & .badge {
      background-color: var(--c-light-18);
      color: var(--c-white);
      font-weight: bold;
    }

    & .title__part-1 {
      margin-top: 3rem;
      font-size: 2.5rem;
    }
    & .title__part-2 {
      margin-top: 1rem;
      font-size: 4rem;
    }

    & .score  {
      font-size: 3rem;
      margin: 2rem 0 4rem;
    }

    & .title--big,
    & .title--default {
      margin-top: 2rem;
    }

    & .title--default {
      font-size: 5rem;
    }

    & .title--big {
      font-size: 5rem;
    }

    & .button-list button {
      height: 7rem;
      width: 7rem;
    }
  }
`
export default LevelResultStyled;