import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLevel } from '../../store/game/selectors';
import { ZoneYears } from '../../data';
import wrapper_3x from '../../assets/graphics/90s/wrapper/90s_wrapper@3x.png';
import wrapper_2x from '../../assets/graphics/90s/wrapper/90s_wrapper@3x.png';
import wrapper from '../../assets/graphics/90s/wrapper/90s_wrapper.png';
import BaseImg from '../ui/BaseImg';
import LevelInfoStyled from '../ui/LevelInfoStyled';
import { getSrcset } from '../../helpers';
import { LevelInfoProps } from '../../models';

export default function LevelInfo({ zone }: LevelInfoProps) {
  const level = useSelector(getCurrentLevel);
  const year = ZoneYears[zone];
  const set = getSrcset([wrapper, wrapper_2x, wrapper_3x]);

  return (
    <LevelInfoStyled className={zone.toLowerCase()}>
      <BaseImg
        src={wrapper_3x}
        srcset={set}
        alt="Wrapper"
      />
      <div className="game-name">Puzzle Blocks</div>
      <div className="game-level-block">
        <p className="game-year">
          {year}
          <span className="game-year-century">s</span>
        </p>
        <p className="game-level">Level {level}</p>
      </div>
    </LevelInfoStyled>
  );
}