import React from 'react';
import styled from 'styled-components';

const IconReplay80sStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2.65px;}
`

export default function IconReplay80s() {
  return (
      <IconReplay80sStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 105.94"
      >
        <polyline className="cls-1" points="34.56 69.82 34.56 81.6 87.87 81.6 87.87 24.34 36.6 24.34 36.6 51.59"/>
        <polyline className="cls-1" points="46.99 41.35 36.44 52.97 26.96 41.35"/>
      </IconReplay80sStyled>
  );
}