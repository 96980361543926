import React, { useContext, useEffect } from 'react';
import MainRouter from './components/MainRouter';
import styled from 'styled-components';
import ServiceContext from './context/serviceContext';
import { useDispatch } from 'react-redux';
import { setLevelsConfig, setUserProgress } from './store/game/actions';
//import { setUser } from './store/user/actions';
//import { UserState } from './store/user/types';
import ThemeProvider from './context/ThemeProvider';
import gameConfig from './data/initialConfig.json';
import { LevelProgress } from './models/GameModel';

const AppStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.mainFont};
  height: 100%;
`

function App() {
  const {
    gameService,
    userService,
  } = useContext(ServiceContext);
  const dispatch = useDispatch();

  useEffect(() => {
    //function setCookie(userdId: number) {
    //  const date = new Date();
    //  const currentMonth = date.getMonth();
    //  date.setMonth(currentMonth + 1);
    //  const expireDate = date.toUTCString();
    //  const cookieStr = `_oracle_play=${userdId};expires=${expireDate};path=/;`;
    //  document.cookie = cookieStr;
    //}
    async function getProgress(id: number) {
      //const progress = await gameService.getProgress(id);
      const progress: LevelProgress = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0
      }
      if (progress) {
        dispatch(setUserProgress(progress))
      }
    }
    async function getUserId() {
      //const cookies = document.cookie;
      //const parts = cookies.split(' ');
      //const userCookie = parts.find(item => item.includes('_oracle_play'));
      //let userInfo: UserState = {
      //  userId: null,
      //  team: null,
      //  nickname: null
      //};
      //if (userCookie) {
      //  const id = userCookie.split('=')[1];
      //  userInfo.userId = +id;
      //  const user = await userService.getUserInfo(+id);
      //  if (user) {
      //    userInfo.nickname = user.nickname_id;
      //    userInfo.team = user.team_id;
      //  }
      //} else {
      //  const { id } = await userService.addUser();
      //  userInfo.userId = id;
      //  setCookie(id);
      //}
      //dispatch(setUser(userInfo));
      getProgress(12323123);
      //await getProgress(userInfo.userId);
    }
    getUserId();
  }, [userService, gameService, dispatch])

  useEffect(() => {
    //async function getLevelsInfo() {
    //  const gameId = await gameService.getGameInfo();
    //  if (gameId) {
    //    const levelsInfo = await gameService.getGameLevels(gameId.id);
    //    if (levelsInfo) {
    //      //levelsInfo[1].time = 3660;
    //      dispatch(setLevelsConfig(levelsInfo))
    //    }
    //  }
    //}
    //getLevelsInfo();
    const levelsInfo = gameConfig;
    dispatch(setLevelsConfig(levelsInfo))
  }, [gameService, dispatch])

  return (
    <ThemeProvider>
      <AppStyled>
        <MainRouter />
      </AppStyled>
    </ThemeProvider>
  );
}

export default App;
