import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { media } from '../../helpers';
import { ButtonConfig } from '../../models';
import BaseButton from '../ui/BaseButton';
import IconOracle from '../icons/IconOracle';
import IconTitle from '../icons/IconTitle';
import { Modal } from '../ui/Modal';
import ChooseTeam from './ChooseTeam';
import LevelDescriptionModal from '../LevelDescriptionModal';
import { useDispatch } from 'react-redux';
import { resetState } from '../../store/game/actions';

const StartPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--c-light-13);
  width: 100%;
  height: 100vh;
  padding: 5rem;
  color: var(--c-black);
  font-family: 'Oracle Sans';

  ${
    media.tablet`
      padding: 2rem;
    `
  }

  & .logo-wrapper {
    top: 0;
    right: 10rem;
    position: absolute;
    width: 7rem;
    height: 7rem;
  }

  & .title-wrapper {
    max-width: 40rem;
  }

  & .button-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15rem;

    ${
      media.tablet`
        flex-direction: column;
      `
    }
  }

  & button {
    font-size: 2rem;
    width: 20rem;
    border-radius: .6rem;
    font-weight: bold;
    height: 6rem;
  }

  & button:not(:first-child) {
    margin-left: 1.5rem;

    ${
      media.tablet`
        margin: 1.5rem 0 0 0;
      `
    }
  }

  & .leaderBoard {
    background-color: transparent;
    border: .1rem solid var(--c-white);
    color: var(--c-white);
  }

  & .solo {
    background-color: var(--c-light-11);
    color: var(--c-white);
  }

  & .team {
    background-color: var(--c-light-2);
    color: var(--c-black);
  }

  & .icon {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    height: 3rem;
  }

  .backdrop {
    background-color: var(--c-black);
  }
`


export default function StartPage() {
  const [isModal, setIsModal] = useState(false);
  const [modalName, setModalName] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch])

  const goToLeaderboard = () => {
    console.log('leaderborad');
  }
  const toggleTeamModal = () => {
    setModalName('TEAM');
    setIsModal(!isModal);
  }
  const toggleLevelModal = () => {
    setModalName('LEVEL');
    setIsModal(!isModal);
  }
  const closeModal = () => {
    setIsModal(false);
    setModalName('');
  }
  const buttonList: ButtonConfig[] = [
    {
      text: 'LeaderBoard',
      className: 'leaderBoard',
      handler: goToLeaderboard,
    },
    {
      text: 'Play solo',
      className: 'solo',
      handler: toggleLevelModal,
    },
    {
      text: 'Play team',
      className: 'team',
      handler: toggleTeamModal,
    },
  ]
  return (
    <StartPageStyled>
      <div className="logo-wrapper">
        <IconOracle />
      </div>
      <div className="title-wrapper">
        <IconTitle />
      </div>
      <div className="button-list">
        {
          buttonList.map(config => {
            return (
              <BaseButton
                handler={config.handler}
                key={config.text}
                className={config.className}
              >
                {config.text}
              </BaseButton>
            )
          })
        }
      </div>
      <Modal
        visible={isModal && !!modalName}
        hide={closeModal}
        className="black"
      >
        {
          modalName === 'LEVEL' && <LevelDescriptionModal />
        }
        {
          modalName === 'TEAM' && <ChooseTeam />
        }
      </Modal>
    </StartPageStyled>
  );
}