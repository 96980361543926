import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import {
  GameStatus,
  TetrisGameState,
  LevelResult,
  Zone
} from '../../models';
import Timer from './Timer';
import Tetris from './Tetris';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentLevel,
  getPlayingLevelConfig,
  getPlayedLevelProgress
} from '../../store/game/selectors';
import { setLevelResult, setNextLevel } from '../../store/game/actions';
import bg_90s_3x from '../../assets/graphics/90s/desktop-bg/90s_bg_desktop@3x.jpg';
import bg_90s_2x from '../../assets/graphics/90s/desktop-bg/90s_bg_desktop@2x.jpg';
import bg_90s from '../../assets/graphics/90s/desktop-bg/90s_bg_desktop.jpg';
import bg_20s_3x from '../../assets/graphics/20s/desktop-bg/20s_bg_desktop@3x.jpg';
import bg_20s_2x from '../../assets/graphics/20s/desktop-bg/20s_bg_desktop@2x.jpg';
import bg_20s from '../../assets/graphics/20s/desktop-bg/20s_bg_desktop.jpg';
import mobile_bg_90s_3x from '../../assets/graphics/90s/mobile-bg/mobile_90s@3x.jpg';
import mobile_bg_90s_2x from '../../assets/graphics/90s/mobile-bg/mobile_90s@2x.jpg';
import mobile_bg_90 from '../../assets/graphics/90s/mobile-bg/mobile_90s.jpg';
import mobile_bg_20s_3x from '../../assets/graphics/20s/mobile-bg/mobile_20s@3x.jpg';
import mobile_bg_20s_2x from '../../assets/graphics/20s/mobile-bg/mobile_20s@2x.jpg';
import mobile_bg_20s from '../../assets/graphics/20s/mobile-bg/mobile_20s.jpg';
import LevelInfo from './LevelInfo';
import PointsBoard from './PointsBoard';
import { AntiSwipeResolutions } from '../../data';
import { getSrcset, stopEvent } from '../../helpers';
import GameMobileControls from './GameMobileControls';
import { useHistory } from 'react-router';
import useWindowSize from '../../hooks/useWindowSize';
import BaseImg from '../ui/BaseImg';
import MuteButton from '../ui/MuteButton';
import soundManager from '../../helpers/soundManager';
import GamePageStyled from '../ui/GamePageStyled';
import ThemeContext from '../../context/themeContext';

const bgConfig = {
  [Zone.ZONE_80s]: null,
  [Zone.ZONE_90s]: {
    src: bg_90s_3x,
    srcset: getSrcset([bg_90s, bg_90s_2x, bg_90s_3x])
  },
  [Zone.ZONE_20s]: {
    src: mobile_bg_20s_3x,
    srcset: getSrcset([bg_20s, bg_20s_2x, bg_20s_3x])
  },
}

const mobileBgConfig = {
  [Zone.ZONE_80s]: null,
  [Zone.ZONE_90s]: {
    src: mobile_bg_90s_3x,
    srcset: getSrcset([mobile_bg_90, mobile_bg_90s_2x, mobile_bg_90s_3x])
  },
  [Zone.ZONE_20s]: {
    src: mobile_bg_20s_3x,
    srcset: getSrcset([mobile_bg_20s, mobile_bg_20s_2x, mobile_bg_20s_3x])
  },
}

export default function GamePage() {
  const [gameState, setGameState] = useState<GameStatus>();
  const [points, setPoints] = useState(0);
  const [lines, setLines] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [modaltext, setModaltext] = useState('');
  const windowSize = useWindowSize();
  const { push } = useHistory();
  const { zone } = useContext(ThemeContext);
  const bg = bgConfig[zone];
  const mobileBg = mobileBgConfig[zone];
  const level = useSelector(getCurrentLevel);
  const levelConfig = useSelector(getPlayingLevelConfig());
  const bestScore = useSelector(getPlayedLevelProgress);
  const dispatch = useDispatch();

  const {
    bg_sound,
    line_cleared_sound,
    block_hit_bottom_sound,
    block_rotate_sound,
    level_lost_sound,
    level_won_sound
  } = soundManager(zone);

  const bg_s = useRef(new Audio(bg_sound));
  bg_s.current.loop = true;
  const line_cleared_s = useRef(new Audio(line_cleared_sound));
  const block_bottom_s = useRef(new Audio(block_hit_bottom_sound));
  const block_rotate_s = useRef(new Audio(block_rotate_sound));
  const level_lost_s = useRef(new Audio(level_lost_sound));
  const level_won_s = useRef(new Audio(level_won_sound));

  useEffect(() => {
    const bg_s_container = bg_s.current;
    async function playBgMusic() {
      await bg_s_container.play();
    }
    playBgMusic()
    return () => bg_s_container.pause();
  }, [])

  useEffect(() => {
    document.body.addEventListener('BLOCK_ROTATE', playRotateSound);
    document.body.addEventListener('BLOCK_HITS_BOTTOM', playBlockHitsBottomSound);
    return () => {
      document.body.removeEventListener('BLOCK_ROTATE', playRotateSound);
      document.body.removeEventListener('BLOCK_HITS_BOTTOM', playBlockHitsBottomSound);
    }
  }, [])

  useEffect(() => {
    if (gameState === GameStatus.LOST) {
      setModaltext('Out of space!');
      setIsPaused(true);
      bg_s.current.pause();
      level_lost_s.current.play();
    }
    if (gameState === GameStatus.STOPPED) {
      bg_s.current.pause();
    }
  }, [gameState]);

  const playRotateSound = () => {
    block_rotate_s.current.currentTime = 0;
    block_rotate_s.current.play();
  }

  const playBlockHitsBottomSound = () => {
    block_bottom_s.current.play();
  }

  const returnToLevelMap = useCallback(() => {
    const status = gameState !== GameStatus.LOST
      ? GameStatus.WON
      : GameStatus.LOST;
    const gameResult: LevelResult = {
      score: points,
      bestScore: bestScore,
      status: status,
    };
    if (status ===  GameStatus.WON && level) {
      dispatch(setNextLevel(level + 1));
    }
    dispatch(setLevelResult(gameResult));
    push('/level-map.html');
  }, [gameState, level, dispatch, push, points, bestScore])

  const updateScore = async ({points, linesCleared}: TetrisGameState) => {
    if (points === 0 && linesCleared === 0) return;
    setPoints(points);
    if (lines !== linesCleared) {
      setLines(linesCleared);
      await line_cleared_s.current.play();
    }
  }

  const updateGameStatus = (status: GameStatus) => {
    setGameState(status);
  }

  const endTimeHandler = () => {
    setModaltext('Level complete!');
    document.body.dispatchEvent(stopEvent);
    level_won_s.current.play();
  }

  const toggleAudioMuteState = useCallback((isMute: boolean) => {
    bg_s.current.muted = isMute;
    line_cleared_s.current.muted = isMute;
    block_bottom_s.current.muted = isMute;
    block_rotate_s.current.muted = isMute;
    level_lost_s.current.muted = isMute;
    level_won_s.current.muted = isMute;
  }, [
    bg_s,
    line_cleared_s,
    block_bottom_s,
    block_rotate_s,
    level_lost_s,
    level_won_s
  ])

  if (!levelConfig) {
    push('/level-map.html');
    return null;
  }

  return (
    <GamePageStyled className={zone.toLowerCase()}>
      {
        bg
        && mobileBg
        && <div className="game-background">
          <BaseImg
            src={bg.src}
            srcset={bg.srcset}
            alt="Game background"
            className="desktop-image"
          />
          <BaseImg
            src={mobileBg.src}
            srcset={mobileBg.srcset}
            alt="Game background"
            className="mobile-image"
          />
        </div>
      }
      <LevelInfo zone={zone} />
      <Tetris
        scoreCallback={updateScore}
        gameStatusCallback={updateGameStatus}
        speed={levelConfig.speed}
        modalCallback={returnToLevelMap}
        text={modaltext}
        zone={zone}
      />
      {
        windowSize
        && !AntiSwipeResolutions.includes(windowSize.resolution)
        && <GameMobileControls />
      }
      <div className="timer-wrapper">
        <Timer
          time={levelConfig.time}
          isPaused={isPaused}
          zone={zone}
          timeEndHandler={endTimeHandler}
        />
        <MuteButton
          callback={toggleAudioMuteState}
          zone={zone}
          className="mute-button"
        />
      </div>
      <PointsBoard 
        points={points}
        zone={zone}
      />
    </GamePageStyled>
  );
}
