import React from 'react';
import styled from 'styled-components';
import { BaseImgProps } from '../../models';

const Img = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export default function BaseImg({src, alt, srcset, className}: BaseImgProps) {
  return (
    <Img
      className={className}
      src={src}
      srcSet={srcset}
      alt={alt}
    />
  );
}