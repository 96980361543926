import { BaseResponse } from "../models/GameModel";

interface HttpClientInt {
  post(url: string, body?: any): Promise<any>;
  get(url: string): Promise<any>;
}

const baseUrl = 'https://apexapps-stage.oracle.com/pls/apex/ocom/oplay/api/v1';

class HttpClient implements HttpClientInt {
  async post(url: string, body?: any) {
    const response = await fetch(
      `${baseUrl}${url}`,
      {
        method: 'POST',
        body: JSON.stringify(body)
      }
    )
    return await response.json();
  }

  async get<T>(url: string) {
    const response =  await fetch(
      `${baseUrl}/${url}`,
    )
    const res: BaseResponse<T> = await response.json();
    return new Response<T>(res);
  }
  async put(url: string, body?: any) {
    const response =  await fetch(
      `${baseUrl}${url}`,
      {
        method: 'PUT',
        body: JSON.stringify(body)
      }
    )
    return await response.json();
  }
}

export interface ResponseInt<T> {
  getData: () => T[] | null;
}

class Response<T> implements ResponseInt<T> {
  private data: T[] | null = null;

  constructor(response: BaseResponse<T>) {
    this.data = response.items.length > 0 ? response.items : null;
  }

  getData() {
    return this.data;
  }
}

const httpClient = new HttpClient();
export default httpClient;
export {
  Response,
}