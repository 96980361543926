import { GameRepository } from '../repositories';
import {
  GameInfo,
  LevelInfo,
  ProgressUpdateDTO,
  LevelProgress,
  LevelConfigMap
} from '../models/GameModel';
import { ResponseInt } from '../httpClient';
import { convertLevelProgress, convertLevelConfig } from '../helpers';

export interface GameService {
  getGameInfo(): Promise<GameInfo | null>;
  getGameLevels(gameId: number): Promise<LevelConfigMap | null>;
  getTopLeaderboard(): Promise<ResponseInt<any>>;
  getLeaderboardTeams(): Promise<ResponseInt<any>>;
  getLevel(levelId: number): Promise<LevelInfo>;
  getNicknames(): Promise<ResponseInt<any>>;
  getProgress(userId: number): Promise<LevelProgress>;
  addProgress(update: ProgressUpdateDTO): Promise<void>;
  updateProgress(update: any): Promise<ResponseInt<any>>;
  getTeam(): Promise<ResponseInt<any>>;
}

export class GameServiceImpl implements GameService {
  private gameRepository: GameRepository;

  constructor(gameRepository: GameRepository) {
    this.gameRepository = gameRepository;
  }

  async getGameInfo() {
    const response = await this.gameRepository.getGameInfo();
    const data = response.getData();
    return data
      ? data.find(item => item.title.toLowerCase().includes('tetris')) || null
      : null;
  }
  async getGameLevels(gameId: number): Promise<LevelConfigMap | null> {
    const response = await this.gameRepository.getGameLevels(gameId);
    const data = response.getData();
    return data
      ? convertLevelConfig(data)
      : data;
  }
  async getTopLeaderboard(): Promise<any> {
    return await this.gameRepository.getTopLeaderboard();
  }
  async getLeaderboardTeams(): Promise<any> {
    return await this.gameRepository.getLeaderboardTeams();
  }
  async getLevel(levelId: number): Promise<any> {
    const response = await this.gameRepository.getLevel(levelId);
    return response.getData();
  }
  async getNicknames(): Promise<any> {
    return await this.gameRepository.getNicknames();
  }
  async getProgress(userId: number): Promise<any> {
    const response = await this.gameRepository.getProgress(userId);
    const data = response.getData();
    return data
      ? convertLevelProgress(data)
      : data;
  }
  async addProgress(update: ProgressUpdateDTO) {
    await this.gameRepository.addProgress(update);
  }
  async updateProgress(update: any): Promise<any> {
    return await this.gameRepository.updateProgress(update);
  }
  async getTeam(): Promise<any> {
    return await this.gameRepository.getTeam();
  }
}