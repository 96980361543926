import styled from 'styled-components';
import { media } from '../../helpers';

const BaseLevelMapItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 12.6vw;
  position: absolute;
  border: 1.rem solid red;
  text-align: center;
  color: ${({ theme }) => theme.color};

  &.level-3,
  &.level-2,
  &.level-1 {
    color: var(--c-80s);
  }

  & .title,
  & .description {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .3rem;
    font-size: 2.5rem;
    width: 100%;
    ${
      media.desktopXL`
        font-size: 2vw;
      `
    }
    ${
      media.desktopL`
        font-size: 1.5vw;
      `
    }
    ${
      media.laptop`
        font-size: 1.5vw;
      `
    }
  }

  & .title {
    height: 4vw;
  }

  & .description {
    height: 8.6vw;
    cursor: pointer;
  }

  &.level-6 .description,
  &.level-5 .description,
  &.level-4 .description {
    height: 11.6vw;
    padding: 1rem;
    border-radius: 50%;
  }

  &.level-3 .title,
  &.level-2 .title,
  &.level-1 .title {
    height: 5.6vw;
  }

  &.level-3 .description,
  &.level-2 .description,
  &.level-1 .description {
    height: 10.7vw;
    padding: 1rem;
  }

  & .icon-wrapper {
    width: 3vw;
    height: 4.5vw;
  }
`

const MobileLevelMapItemStyled = styled(BaseLevelMapItemStyled)`
  &.level-9 {
    top: 6.5vw;
    left: 33.5%;
  }

  &.level-8 {
    top: 27.5vw;
    left: 52.8%;
  }

  &.level-7 {
    top: 48.8vw;
    left: 33.6%;
  }

  &.level-6 {
    top: 71vw;
    left: 52.8%;
  }

  &.level-5 {
    top: 90.5vw;
    left: 34%;
  }

  &.level-4 {
    top: 112.8vw;
    left: 52.5%;
  }

  &.level-3 {
    top: 134.2vw;
    left: 33.7%;
  }

  &.level-2 {
    top: 156.3vw;
    left: 52.8%;
  }

  &.level-1 {
    top: 178.5vw;
    left: 33.9%;
  }
`

const DesktopLevelMapItemStyled = styled(BaseLevelMapItemStyled)`
  &.level-9 {
    top: 6.5vw;
    left: 33.4%;
  }

  &.level-8 {
    top: 27.2vw;
    left: 52.7%;
  }

  &.level-7 {
    top: 48.4vw;
    left: 33.6%;
  }

  &.level-6 {
    top: 70.3vw;
    left: 52.8%;
  }

  &.level-5 {
    top: 89.7vw;
    left: 34%;
  }

  &.level-4 {
    top: 111.6vw;
    left: 52.5%;
  }

  &.level-3 {
    top: 132.8vw;
    left: 33.7%;
  }

  &.level-2 {
    top: 154.7vw;
    left: 52.8%;
  }

  &.level-1 {
    top: 176.6vw;
    left: 33.9%;
  }
`
export {
  DesktopLevelMapItemStyled,
  MobileLevelMapItemStyled
}