import React from 'react';
import styled from 'styled-components';

const IconReplay90sStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:#ede7b7;}
  .cls-2{fill:#f4ee31;}
  .cls-3{fill:#c4bc23;}
  .cls-4{fill:#0c0c0c;}
`

export default function IconReplay90s() {
  return (
      <IconReplay90sStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.83 116.22"
      >
        <polygon
          className="cls-1"
          points="104.06 16.95 104.06 22.43 104.06 27.93 104.06 33.41 104.06 38.9 104.06 44.39 104.06 49.88 104.06 55.36 104.06 60.86 104.06 66.34 104.06 71.83 104.06 77.32 104.06 82.81 104.06 88.29 104.06 93.79 104.06 99.27 109.55 99.27 109.55 93.79 109.55 88.29 109.55 82.81 109.55 77.32 109.55 71.83 109.55 66.34 109.55 60.86 109.55 55.36 109.55 49.88 109.55 44.39 109.55 38.9 109.55 33.41 109.55 27.93 109.55 22.43 109.55 16.95 104.06 16.95"
        />
        <rect
          className="cls-1"
          x="98.58" y="11.46" width="5.48" height="5.48"
        />
        <polygon
          className="cls-1"
          points="93.08 5.96 87.6 5.96 82.11 5.96 76.61 5.96 71.13 5.96 65.65 5.96 60.15 5.96 54.67 5.96 49.18 5.96 43.69 5.96 38.2 5.96 32.72 5.96 27.22 5.96 21.74 5.96 16.25 5.96 16.25 11.46 21.74 11.46 27.22 11.46 32.72 11.46 38.2 11.46 43.69 11.46 49.18 11.46 54.67 11.46 60.15 11.46 65.65 11.46 71.13 11.46 76.61 11.46 82.11 11.46 87.6 11.46 93.08 11.46 98.58 11.46 98.58 5.96 93.08 5.96"
        />
        <polygon
          className="cls-2"
          points="98.58 16.95 98.58 11.46 93.08 11.46 87.6 11.46 82.11 11.46 76.61 11.46 71.13 11.46 65.65 11.46 60.15 11.46 54.67 11.46 49.18 11.46 43.69 11.46 38.2 11.46 32.72 11.46 27.22 11.46 21.74 11.46 16.25 11.46 10.76 11.46 10.76 16.95 10.76 22.43 10.76 27.93 10.76 33.41 10.76 38.9 10.76 44.39 10.76 49.88 10.76 55.36 10.76 60.86 10.76 66.34 10.76 71.83 10.76 77.32 10.76 82.81 10.76 88.29 10.76 93.79 10.76 99.27 16.25 99.27 16.25 104.75 21.74 104.75 27.22 104.75 32.72 104.75 38.2 104.75 43.69 104.75 49.18 104.75 54.67 104.75 60.15 104.75 65.65 104.75 71.13 104.75 76.61 104.75 82.11 104.75 87.6 104.75 93.08 104.75 98.58 104.75 104.06 104.75 104.06 99.27 104.06 93.79 104.06 88.29 104.06 82.81 104.06 77.32 104.06 71.83 104.06 66.34 104.06 60.86 104.06 55.36 104.06 49.88 104.06 44.39 104.06 38.9 104.06 33.41 104.06 27.93 104.06 22.43 104.06 16.95 98.58 16.95"
        />
        <polygon
          className="cls-3"
          points="93.08 104.75 87.6 104.75 82.11 104.75 76.61 104.75 71.13 104.75 65.65 104.75 60.15 104.75 54.67 104.75 49.18 104.75 43.69 104.75 38.2 104.75 32.72 104.75 27.22 104.75 21.74 104.75 16.25 104.75 16.25 110.25 21.74 110.25 27.22 110.25 32.72 110.25 38.2 110.25 43.69 110.25 49.18 110.25 54.67 110.25 60.15 110.25 65.65 110.25 71.13 110.25 76.61 110.25 82.11 110.25 87.6 110.25 93.08 110.25 98.58 110.25 98.58 104.75 93.08 104.75"
        />
        <rect
          className="cls-3"
          x="10.75" y="99.27" width="5.5" height="5.48"
        />
        <polygon
          className="cls-3"
          points="10.76 88.29 10.76 82.81 10.76 77.32 10.76 71.83 10.76 66.34 10.76 60.86 10.76 55.36 10.76 49.88 10.76 44.39 10.76 38.9 10.76 33.41 10.76 27.93 10.76 22.43 10.76 16.95 5.27 16.95 5.27 22.43 5.27 27.93 5.27 33.41 5.27 38.9 5.27 44.39 5.27 49.88 5.27 55.36 5.27 60.86 5.27 66.34 5.27 71.83 5.27 77.32 5.27 82.81 5.27 88.29 5.27 93.79 5.27 99.27 10.76 99.27 10.76 93.79 10.76 88.29"
        />
        <polygon
          className="cls-4"
          points="109.55 16.95 109.55 22.43 109.55 27.93 109.55 33.41 109.55 38.9 109.55 44.39 109.55 49.88 109.55 55.36 109.55 60.86 109.55 66.34 109.55 71.83 109.55 77.32 109.55 82.81 109.55 88.29 109.55 93.79 109.55 99.27 115.05 99.27 115.05 93.79 115.05 88.29 115.05 82.81 115.05 77.32 115.05 71.83 115.05 66.34 115.05 60.86 115.05 55.36 115.05 49.88 115.05 44.39 115.05 38.9 115.05 33.41 115.05 27.93 115.05 22.43 115.05 16.95 109.55 16.95"
        />
        <rect
          className="cls-4"
          x="104.06" y="99.27" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="104.06" y="11.46" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="98.58" y="104.76" width="5.48" height="5.5"
        />
        <rect
          className="cls-4"
          x="98.58" y="5.96" width="5.48" height="5.5"
        />
        <polygon
          className="cls-4"
          points="87.6 110.25 82.11 110.25 76.61 110.25 71.13 110.25 65.65 110.25 60.15 110.25 54.67 110.25 49.18 110.25 43.69 110.25 38.2 110.25 32.72 110.25 27.22 110.25 21.74 110.25 16.25 110.25 16.25 115.74 21.74 115.74 27.22 115.74 32.72 115.74 38.2 115.74 43.69 115.74 49.18 115.74 54.67 115.74 60.15 115.74 65.65 115.74 71.13 115.74 76.61 115.74 82.11 115.74 87.6 115.74 93.08 115.74 98.58 115.74 98.58 110.25 93.08 110.25 87.6 110.25"
        />
        <polygon
          className="cls-4"
          points="27.22 5.96 32.72 5.96 38.2 5.96 43.69 5.96 49.18 5.96 54.67 5.96 60.15 5.96 65.65 5.96 71.13 5.96 76.61 5.96 82.11 5.96 87.6 5.96 93.08 5.96 98.58 5.96 98.58 0.48 93.08 0.48 87.6 0.48 82.11 0.48 76.61 0.48 71.13 0.48 65.65 0.48 60.15 0.48 54.67 0.48 49.18 0.48 43.69 0.48 38.2 0.48 32.72 0.48 27.22 0.48 21.74 0.48 16.25 0.48 16.25 5.96 21.74 5.96 27.22 5.96"
        />
        <rect
          className="cls-4"
          x="10.75" y="104.76" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="10.75" y="5.96" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="5.27" y="99.27" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="5.27" y="11.46" width="5.48" height="5.48"
        />
        <polygon
          className="cls-4"
          points="5.27 88.29 5.27 82.81 5.27 77.32 5.27 71.83 5.27 66.34 5.27 60.86 5.27 55.36 5.27 49.88 5.27 44.39 5.27 38.9 5.27 33.41 5.27 27.93 5.27 22.43 5.27 16.95 -0.21 16.95 -0.21 22.43 -0.21 27.93 -0.21 33.41 -0.21 38.9 -0.21 44.39 -0.21 49.88 -0.21 55.36 -0.21 60.86 -0.21 66.34 -0.21 71.83 -0.21 77.32 -0.21 82.81 -0.21 88.29 -0.21 93.79 -0.21 99.27 5.27 99.27 5.27 93.79 5.27 88.29"
        />
        <polygon
          className="cls-4"
          points="87.61 41.64 87.61 47.13 87.61 52.63 87.61 58.11 87.61 63.59 87.61 69.09 87.61 74.58 93.09 74.58 93.09 69.09 93.09 63.59 93.09 58.11 93.09 52.63 93.09 47.13 93.09 41.64 87.61 41.64"
        />
        <rect
          className="cls-4"
          x="82.12" y="74.57" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="82.12" y="36.16" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="76.62" y="80.06" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="76.62" y="30.66" width="5.5" height="5.5"
        />
        <polygon
          className="cls-4"
          points="71.14 25.18 65.66 25.18 60.16 25.18 54.67 25.18 49.19 25.18 43.69 25.18 43.69 30.66 49.19 30.66 54.67 30.66 60.16 30.66 65.66 30.66 71.14 30.66 76.62 30.66 76.62 25.18 71.14 25.18"
        />
        <polygon
          className="cls-4"
          points="65.66 85.56 60.16 85.56 54.67 85.56 49.19 85.56 43.69 85.56 43.69 91.04 49.19 91.04 54.67 91.04 60.16 91.04 65.66 91.04 71.14 91.04 76.62 91.04 76.62 85.56 71.14 85.56 65.66 85.56"
        />
        <rect
          className="cls-4"
          x="43.69" y="41.64" width="5.5" height="5.48"
        />
        <rect
          className="cls-4"
          x="38.21" y="80.06" width="5.48" height="5.5"
        />
        <rect
          className="cls-4"
          x="38.21" y="30.66" width="5.48" height="5.5"
        />
        <rect
          className="cls-4"
          x="32.73" y="74.57" width="5.48" height="5.48"
        />
        <polygon
          className="cls-4"
          points="32.73 41.64 32.73 47.13 27.23 47.13 27.23 52.63 32.73 52.63 32.73 58.11 38.21 58.11 38.21 52.63 43.69 52.63 43.69 47.13 38.21 47.13 38.21 41.64 38.21 36.16 32.73 36.16 32.73 41.64"
        />
        <rect
          className="cls-4"
          x="21.75" y="41.64" width="5.48" height="5.48"/>
      </IconReplay90sStyled>
  );
}