import React from 'react';
import styled from 'styled-components';

const IconShare90sStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:#ede7b7;}.cls-2{fill:#f4ee31;}.cls-3{fill:#c4bc23;}.cls-4{fill:#0c0c0c;}
`

export default function IconShare90s() {
  return (
      <IconShare90sStyled
        id="_80s_mockup_flow"
        data-name="80s_mockup_flow"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 114.56 115.26"
      >
        <polygon
          className="cls-1"
          points="103.93 16.46 103.93 21.95 103.93 27.45 103.93 32.93 103.93 38.41 103.93 43.91 103.93 49.4 103.93 54.88 103.93 60.38 103.93 65.86 103.93 71.34 103.93 76.84 103.93 82.33 103.93 87.81 103.93 93.31 103.93 98.79 109.41 98.79 109.41 93.31 109.41 87.81 109.41 82.33 109.41 76.84 109.41 71.34 109.41 65.86 109.41 60.38 109.41 54.88 109.41 49.4 109.41 43.91 109.41 38.41 109.41 32.93 109.41 27.45 109.41 21.95 109.41 16.46 103.93 16.46"
        />
        <rect
          className="cls-1"
          x="98.44" y="10.98" width="5.48" height="5.48"
        />
        <polygon
          className="cls-1"
          points="92.94 5.48 87.46 5.48 81.98 5.48 76.48 5.48 71 5.48 65.51 5.48 60.01 5.48 54.53 5.48 49.05 5.48 43.55 5.48 38.07 5.48 32.58 5.48 27.08 5.48 21.6 5.48 16.12 5.48 16.12 10.98 21.6 10.98 27.08 10.98 32.58 10.98 38.07 10.98 43.55 10.98 49.05 10.98 54.53 10.98 60.01 10.98 65.51 10.98 71 10.98 76.48 10.98 81.98 10.98 87.46 10.98 92.94 10.98 98.44 10.98 98.44 5.48 92.94 5.48"
        />
        <polygon
          className="cls-2"
          points="98.44 16.46 98.44 10.98 92.94 10.98 87.46 10.98 81.98 10.98 76.48 10.98 71 10.98 65.51 10.98 60.01 10.98 54.53 10.98 49.05 10.98 43.55 10.98 38.07 10.98 32.58 10.98 27.08 10.98 21.6 10.98 16.12 10.98 10.62 10.98 10.62 16.46 10.62 21.95 10.62 27.45 10.62 32.93 10.62 38.41 10.62 43.91 10.62 49.4 10.62 54.88 10.62 60.38 10.62 65.86 10.62 71.34 10.62 76.84 10.62 82.33 10.62 87.81 10.62 93.31 10.62 98.79 16.12 98.79 16.12 104.27 21.6 104.27 27.08 104.27 32.58 104.27 38.07 104.27 43.55 104.27 49.05 104.27 54.53 104.27 60.01 104.27 65.51 104.27 71 104.27 76.48 104.27 81.98 104.27 87.46 104.27 92.94 104.27 98.44 104.27 103.93 104.27 103.93 98.79 103.93 93.31 103.93 87.81 103.93 82.33 103.93 76.84 103.93 71.34 103.93 65.86 103.93 60.38 103.93 54.88 103.93 49.4 103.93 43.91 103.93 38.41 103.93 32.93 103.93 27.45 103.93 21.95 103.93 16.46 98.44 16.46"
        />
        <polygon
          className="cls-3"
          points="92.94 104.27 87.46 104.27 81.98 104.27 76.48 104.27 71 104.27 65.51 104.27 60.01 104.27 54.53 104.27 49.05 104.27 43.55 104.27 38.07 104.27 32.58 104.27 27.08 104.27 21.6 104.27 16.12 104.27 16.12 109.77 21.6 109.77 27.08 109.77 32.58 109.77 38.07 109.77 43.55 109.77 49.05 109.77 54.53 109.77 60.01 109.77 65.51 109.77 71 109.77 76.48 109.77 81.98 109.77 87.46 109.77 92.94 109.77 98.44 109.77 98.44 104.27 92.94 104.27"
        />
        <rect
          className="cls-3"
          x="10.62" y="98.79" width="5.5" height="5.48"
        />
        <polygon
          className="cls-3"
          points="10.62 87.81 10.62 82.33 10.62 76.84 10.62 71.34 10.62 65.86 10.62 60.38 10.62 54.88 10.62 49.4 10.62 43.91 10.62 38.41 10.62 32.93 10.62 27.45 10.62 21.95 10.62 16.46 5.14 16.46 5.14 21.95 5.14 27.45 5.14 32.93 5.14 38.41 5.14 43.91 5.14 49.4 5.14 54.88 5.14 60.38 5.14 65.86 5.14 71.34 5.14 76.84 5.14 82.33 5.14 87.81 5.14 93.31 5.14 98.79 10.62 98.79 10.62 93.31 10.62 87.81"
        />
        <polygon
          className="cls-4"
          points="109.41 16.46 109.41 21.95 109.41 27.45 109.41 32.93 109.41 38.41 109.41 43.91 109.41 49.4 109.41 54.88 109.41 60.38 109.41 65.86 109.41 71.34 109.41 76.84 109.41 82.33 109.41 87.81 109.41 93.31 109.41 98.79 114.91 98.79 114.91 93.31 114.91 87.81 114.91 82.33 114.91 76.84 114.91 71.34 114.91 65.86 114.91 60.38 114.91 54.88 114.91 49.4 114.91 43.91 114.91 38.41 114.91 32.93 114.91 27.45 114.91 21.95 114.91 16.46 109.41 16.46"
        />
        <rect
          className="cls-4"
          x="103.93" y="98.79" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="103.93" y="10.98" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="98.44" y="104.27" width="5.48" height="5.5"
        />
        <rect
          className="cls-4"
          x="98.44" y="5.48" width="5.48" height="5.5"
        />
        <polygon
          className="cls-4"
          points="87.46 109.77 81.98 109.77 76.48 109.77 71 109.77 65.51 109.77 60.01 109.77 54.53 109.77 49.05 109.77 43.55 109.77 38.07 109.77 32.58 109.77 27.08 109.77 21.6 109.77 16.12 109.77 16.12 115.26 21.6 115.26 27.08 115.26 32.58 115.26 38.07 115.26 43.55 115.26 49.05 115.26 54.53 115.26 60.01 115.26 65.51 115.26 71 115.26 76.48 115.26 81.98 115.26 87.46 115.26 92.94 115.26 98.44 115.26 98.44 109.77 92.94 109.77 87.46 109.77"
        />
        <polygon
          className="cls-4"
          points="27.08 5.48 32.58 5.48 38.07 5.48 43.55 5.48 49.05 5.48 54.53 5.48 60.01 5.48 65.51 5.48 71 5.48 76.48 5.48 81.98 5.48 87.46 5.48 92.94 5.48 98.44 5.48 98.44 0 92.94 0 87.46 0 81.98 0 76.48 0 71 0 65.51 0 60.01 0 54.53 0 49.05 0 43.55 0 38.07 0 32.58 0 27.08 0 21.6 0 16.12 0 16.12 5.48 21.6 5.48 27.08 5.48"
        />
        <rect
          className="cls-4"
          x="10.62" y="104.27" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="10.62" y="5.48" width="5.5" height="5.5"
        />
        <rect
          className="cls-4"
          x="5.14" y="98.79" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="5.14" y="10.98" width="5.48" height="5.48"
        />
        <polygon
          className="cls-4"
          points="5.14 87.81 5.14 82.33 5.14 76.84 5.14 71.34 5.14 65.86 5.14 60.38 5.14 54.88 5.14 49.4 5.14 43.91 5.14 38.41 5.14 32.93 5.14 27.45 5.14 21.95 5.14 16.46 -0.35 16.46 -0.35 21.95 -0.35 27.45 -0.35 32.93 -0.35 38.41 -0.35 43.91 -0.35 49.4 -0.35 54.88 -0.35 60.38 -0.35 65.86 -0.35 71.34 -0.35 76.84 -0.35 82.33 -0.35 87.81 -0.35 93.31 -0.35 98.79 5.14 98.79 5.14 93.31 5.14 87.81"
        />
        <path
          className="cls-4"
          d="M87.46,32.93H21.6v49.4H93V32.93Zm0,16.47V76.84H27.1V43.91h5.48v-5.5H82v5.5h5.48Z"
        />
        <rect
          className="cls-4"
          x="76.5" y="43.91" width="5.48" height="5.48"
        />
        <rect
          className="cls-4"
          x="71" y="49.4" width="5.5" height="5.48"
        />
        <rect
          className="cls-4"
          x="65.51" y="54.88" width="5.48" height="5.5"
        />
        <polygon
          className="cls-4"
          points="54.53 60.38 49.05 60.38 49.05 65.86 54.53 65.86 60.03 65.86 65.51 65.86 65.51 60.38 60.03 60.38 54.53 60.38"
        />
        <rect
          className="cls-4"
          x="43.57" y="54.88" width="5.48" height="5.5"
        />
        <rect
          className="cls-4"
          x="38.07" y="49.4" width="5.5" height="5.48"
        />
        <rect
          className="cls-4"
          x="32.58" y="43.91" width="5.48" height="5.48"/>
      </IconShare90sStyled>
  );
}