import React, { useCallback, useEffect, useState } from 'react';
import TetrisGameWrapper from 'react-tetris';
import { Zone, TetrisProps } from '../../models';
import GameModal from './GameModal';
import BgZoneImages from './BgZoneImages';
import TetrisStyled from '../ui/TetrisStyled';
import TetrisBoardFakeCells from './TetrisBoardFakeCells';
import Bg90sColumn from './Bg90sColumn';
import Bg90sGreenRow from './Bg90sGreenRow';
import NextBlockBox from './NextBlockBox';

export default function Tetris({
  scoreCallback,
  gameStatusCallback,
  modalCallback,
  speed,
  board,
  text,
  zone
}: TetrisProps) {
  const [isModal, setIsModal] = useState(false);

  useEffect(() => {
    if (text) {
      setIsModal(true);
    }
  }, [text])

  const closeModal = useCallback(() => {
    setIsModal(false);
    modalCallback();
  }, [modalCallback])

  return (
    <TetrisGameWrapper
      scoreCallback={scoreCallback}
      gameStatusCallback={gameStatusCallback}
      blockFallSpeed={speed}
    >
      {({ Gameboard, PieceQueue }: any) => (
        <TetrisStyled className={zone.toLowerCase()}>
          <div
            className="game-board-wrapper"
            id="game-board-wrapper"
          >
            { zone === Zone.ZONE_90s && <Bg90sColumn position="left" /> }
            <div
              id="table-wrapper"
              className="table-wrapper"
            >
              {
                isModal
                && !!text
                && <GameModal
                      className={zone.toLowerCase()}
                      hide={closeModal}
                      text={text}
                   />
              }
              { zone === Zone.ZONE_90s && <Bg90sGreenRow position="top" /> }
              { zone === Zone.ZONE_20s
                && <div className="game-board-bar game-board-bar--top"></div>
              }
              <Gameboard board={board}/>
              { zone === Zone.ZONE_20s
                && <div className="game-board-bar game-board-bar--bottom"></div>
              }
              {
                zone === Zone.ZONE_90s
                && <>
                    <TetrisBoardFakeCells />
                    <Bg90sGreenRow position="bottom" />
                  </>
              }
            </div>
            { zone === Zone.ZONE_90s && <Bg90sColumn position="right" /> }
          </div>
          <BgZoneImages zone={zone} />
          <NextBlockBox
            zone={zone}
            className={`${isModal ? 'hidden' : ''}`}
          >
            <PieceQueue />
          </NextBlockBox>
        </TetrisStyled>
      )}
    </TetrisGameWrapper>
  );
}
