import React from 'react';
import styled from 'styled-components';
import { Zone, ZonedWrapperProps } from '../../models';
import popup_bg_90s_3x from '../../assets/graphics/90s/popup-bg/90s_popup_bg@3x.png';
import popup_bg_90s_2x from '../../assets/graphics/90s/popup-bg/90s_popup_bg@2x.png';
import popup_bg_90s from '../../assets/graphics/90s/popup-bg/90s_popup_bg.png';
import popup_bg_modern_3x from '../../assets/graphics/20s/popup-bg/20s_popup_bg@3x.png';
import popup_bg_modern_2x from '../../assets/graphics/20s/popup-bg/20s_popup_bg@2x.png';
import popup_bg_modern from '../../assets/graphics/20s/popup-bg/20s_popup_bg.png';
import { media, getSrcset } from '../../helpers';
import BaseImg from './BaseImg';

const ZonedWrapperStyled = styled.div`
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.mainFont};
  text-align: center;
  width: 31rem;

  ${
    media.mobileM`
      width: 30rem;
    `
  }

  .bg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: fill;
  }

  &.zone_80s {
    border: .2rem dashed var(--c-80s);
    padding: 1rem;
  }

  &.zone_90s .bg,
  &.zone_20s .bg {
    display: block;
  }
`
const images = {
  [Zone.ZONE_80s]: null,
  [Zone.ZONE_90s]: {
    src: popup_bg_90s_3x,
    srcset: getSrcset([popup_bg_90s, popup_bg_90s_2x, popup_bg_90s_3x])
  },
  [Zone.ZONE_20s]: {
    src: popup_bg_modern_3x,
    srcset: getSrcset([popup_bg_modern, popup_bg_modern_2x, popup_bg_modern_3x])
  },
}

export default function ZonedWrapper({zone, children}: ZonedWrapperProps) {
  const image = images[zone];

  return (
    <ZonedWrapperStyled className={zone.toLowerCase()}>
      {
        image && <BaseImg
          className="bg"
          src={image.src}
          srcset={image.srcset}
          alt="Bg 90s"
        />
      }
      { children }
    </ZonedWrapperStyled>
  );
}