import styled from 'styled-components';
import { media } from '../../helpers';

const TimerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.color};
  font-family: ${({ theme }) => theme.numbersFont};
  grid-area: timer;
  position: relative;

  &.zone_90s span {
    z-index: 2;
  }

  img {
    display: none;
  }

  .separator {
    display: inline-block;
    font-family: ${({ theme }) => theme.mainFont};
  }

  &.zone_80s .separator {
    margin: 0 .1rem;
    align-self: flex-end;
  }

  &.zone_80s {
    border: .3rem solid var(--c-80s);
    font-size: 5rem;
    line-height: 7rem;
    min-width: 16rem;
    vertical-align: middle;

    ${
      media.tablet`
        font-size: 3.2rem;
        line-height: 4.5rem;
        padding: 0;
        min-width: 11rem;
      `
    }
  
    ${
      media.mobileL`
        font-size: 2.5rem;
        line-height: 3rem;
        padding: .5rem;
        min-width: 9rem;
      `
    }
  }

  &.zone_90s img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -4rem;

    ${
      media.tablet`
        display: none;
      `
    }
  }

  &.zone_90s {
    padding: 3rem 0;
    min-width: 16rem;
    justify-content: flex-start;

    ${
      media.tablet`
        padding: 1rem 0;
        align-self: flex-end;
        min-width: 11rem;
        justify-content: flex-end;
        color: var(--c-white);
      `
    }

    ${
      media.mobileM`
        min-width: 10rem;
        font-size: 1.5rem;
      `
    }
  }

  &.zone_20s {
    justify-content: flex-end;
    background-color: var(--c-light-6);
    color: var(--c-white);
    font-size: 3rem;
    padding: 1rem;
    margin-top: 4rem;
    min-width: 14rem;
    border-radius: .5rem;

    ${
      media.tablet`
        font-size: 2rem;
        padding: 1rem;
        margin-top: 0;
        min-width: 11rem;
      `
    }

    ${
      media.mobileM`
        min-width: 9rem;
        font-size: 1.5rem;
      `
    }
  }

  &.zone_20s svg {
    display: block;
    position: absolute;
    top: 55%;
    left: 2rem;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 100%;
  }
`
export default TimerStyled;