import { RootState } from '..';

const getCurrentLevel = (state: RootState) => {
  return state.game.currentLevel
};

const getNextLevel = (state: RootState) => {
  return state.game.nextLevel
};

const getLevelResult = (state: RootState) => {
  return state.game.levelResult;
};

const getTotalScore = (state: RootState) => {
  return state.game.score;
};

const getPlayedLevelProgress = (state: RootState) => {
  const { progress, currentLevel } = state.game;
  return currentLevel && progress ? progress[currentLevel] : null;
};

const getPlayingLevelConfig = (level?: number) => (state: RootState) => {
  const { levelsConfig, currentLevel } = state.game;
  if (level) {
    return levelsConfig ? levelsConfig[level] : null;
  }
  return currentLevel && levelsConfig ? levelsConfig[currentLevel] : null;
};

const isNewBestScore = (state: RootState) => {
  const levelResult = getLevelResult(state);
  if (levelResult) {
    const {score, bestScore} = levelResult;
    return typeof bestScore === 'number'
      ? score > bestScore
      : true;
  } return false;
};

export {
  getCurrentLevel,
  getNextLevel,
  getLevelResult,
  getTotalScore,
  isNewBestScore,
  getPlayingLevelConfig,
  getPlayedLevelProgress
}
