import httpClient, { ResponseInt } from '../httpClient';
import {
  GameInfo,
  LevelInfoWithJSON,
  LevelInfo,
  LevelProgressModel,
  ProgressUpdateDTO
} from '../models/GameModel';

export interface GameRepository {
  getGameInfo(): Promise<ResponseInt<GameInfo>>;
  getGameLevels(gameId: number): Promise<ResponseInt<LevelInfoWithJSON>>;
  getTopLeaderboard(): Promise<ResponseInt<any>>;
  getLeaderboardTeams(): Promise<ResponseInt<any>>;
  getLevel(levelId: number): Promise<ResponseInt<LevelInfo>>;
  getNicknames(): Promise<ResponseInt<any>>;
  getProgress(userId: number): Promise<ResponseInt<LevelProgressModel>>;
  addProgress(update: ProgressUpdateDTO): Promise<ResponseInt<void>>;
  updateProgress(update: any): Promise<ResponseInt<any>>;
  getTeam(): Promise<ResponseInt<any>>;
}

export class GameRepositoryImpl implements GameRepository {
  async getGameInfo() {
    return await httpClient.get<GameInfo>('/game')
  }
  async getGameLevels(gameId: number) {
    return await httpClient.get<LevelInfoWithJSON>(`/game/${gameId}/levels`)
  }
  async getTopLeaderboard() {
    return await httpClient.get<any>('/leaderboard/personal')
  }
  async getLeaderboardTeams() {
    return await httpClient.get<any>('/leaderboard/team')
  }
  async getLevel(levelId: number) {
    return await httpClient.get<LevelInfo>(`/level/${levelId}`)
  }
  async getNicknames() {
    return await httpClient.get<any>('/nickname')
  }
  async getProgress(userId: number) {
    return await httpClient.get<LevelProgressModel>(`/progress?user_id=${userId}`)
  }
  async addProgress(update: ProgressUpdateDTO) {
    return await httpClient.post('/progress', update)
  }
  async updateProgress(update: any) {
    return await httpClient.put('/progress', update)
  }
  async getTeam() {
    return await httpClient.get<any>('/team')
  }
}