import React from 'react';
import styled from 'styled-components';

const IconClockStyled = styled.svg`
  height: 100%;
  width: 100%;

  .cls-1{fill:#fcfbfa;}
`

export default function IconClock() {
  return (
      <IconClockStyled
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30.5 30.5"
        className="icon-clock"
      >
        <path
          className="cls-1"
          d="M15.25,0A15.25,15.25,0,1,0,30.5,15.25h0A15.27,15.27,0,0,0,15.25,0Zm0,29.24a14,14,0,1,1,14-14A14,14,0,0,1,15.25,29.24Z"
        />
        <rect
          className="cls-1"
          x="14.63" y="24.86" width="1.24" height="1.24"
        />
        <rect
          className="cls-1"
          x="24.9" y="14.61" width="1.24" height="1.24"
        />
        <rect
          className="cls-1"
          x="4.36" y="14.61" width="1.24" height="1.24"
        />
        <rect
          className="cls-1"
          x="19.76" y="23.51" width="1.24" height="1.24" transform="translate(-9.34 13.42) rotate(-30)"
        />
        <rect
          className="cls-1"
          x="23.52" y="9.47" width="1.24" height="1.24" transform="translate(-1.81 13.42) rotate(-30)"
        />
        <rect
          className="cls-1"
          x="9.49" y="5.73" width="1.24" height="1.24" transform="translate(-1.82 5.91) rotate(-30)"
        />
        <rect
          className="cls-1"
          x="5.73" y="19.76" width="1.24" height="1.24" transform="translate(-9.34 5.91) rotate(-30)"
        />
        <rect
          className="cls-1"
          x="23.53" y="19.75" width="1.24" height="1.24" transform="translate(-5.57 31.1) rotate(-60)"
        />
        <rect
          className="cls-1"
          x="19.76" y="5.73" width="1.24" height="1.24" transform="translate(4.69 20.83) rotate(-60)"
        />
        <rect
          className="cls-1"
          x="5.73" y="9.49" width="1.24" height="1.24" transform="translate(-5.58 10.56) rotate(-60)"
        />
        <rect
          className="cls-1"
          x="9.49" y="23.51" width="1.24" height="1.24" transform="translate(-15.84 20.82) rotate(-60)"
        />
        <path
          className="cls-1"
          d="M15.25,2.18A13.07,13.07,0,1,0,28.32,15.25h0A13.07,13.07,0,0,0,15.25,2.18Zm0,24.88A11.83,11.83,0,1,1,27.07,15.23h0A11.82,11.82,0,0,1,15.25,27.06Z"
        />
        <path
          className="cls-1"
          d="M20.54,13.35H18.05v1.24h2.49a.63.63,0,0,1,0,1.25H17a2,2,0,0,0,.11-.61v-9a1.87,1.87,0,0,0-3.74,0v9a1.86,1.86,0,0,0,1.87,1.85h5.29a1.87,1.87,0,1,0,0-3.73Zm-5.91,1.88v-9a.62.62,0,1,1,1.24,0v9a.63.63,0,0,1-.62.63A.62.62,0,0,1,14.63,15.23Z"
        />
      </IconClockStyled>
  );
}