import React from 'react';
import styled from 'styled-components';

interface IconMuteProps {
  color?: string;
  isMute: boolean;
}

const IconMuteStyled = styled.svg`
  height: 100%;

  rect,
  polygon {
    fill: ${props => props.color};
  }
  path,
  line {
    fill: none;
    stroke: ${props => props.color};
    stroke-miterlimit:10;
  }
`

export default function IconMute({color = '#fff', isMute}: IconMuteProps) {
  return (
      <IconMuteStyled
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.34 20.79"
        color={color}
      >
        <rect
          y="5.74"
          width="5.37"
          height="9.31"
          transform="translate(5.37 20.79) rotate(180)"
        />
        <polygon
          points="5.37 12.78 18.41 20.79 18.41 0 5.37 8.01 5.37 12.78"
        />
        {
          isMute
            ? <>
                <line
                  x1="32.99"
                  y1="5.75"
                  x2="23.69"
                  y2="15.04"
                />
                <line
                  x1="32.99"
                  y1="15.04"
                  x2="23.69"
                  y2="5.75"
                />
              </>
          : <>
              <path
                d="M23.61,5.74A6,6,0,0,1,26,10.39a6,6,0,0,1-2.41,4.66"
              />
              <path
                d="M26.71,2.84a9.8,9.8,0,0,1,3.9,7.55A9.81,9.81,0,0,1,26.71,18"
              />
            </>
        }
      </IconMuteStyled>
  );
}