import {
  UserRepositoryImpl,
  GameRepositoryImpl
} from '../repositories'

import {
  UserService,
  UserServiceImpl,
  GameService,
  GameServiceImpl
} from '../services'

export interface ServicesList {
  userService: UserService,
  gameService: GameService,
}

export function initServices(): ServicesList {
  const userRepository = new UserRepositoryImpl();
  const gameRepository = new GameRepositoryImpl();
  const userService = new UserServiceImpl(userRepository);
  const gameService = new GameServiceImpl(gameRepository);

  return {
    userService,
    gameService
  }
}