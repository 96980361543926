import React from 'react';
import { PointsBoardProps } from '../../models';
import PointsBoardStyled from '../ui/PointsBoardStyled';

export default function PointsBoard({points, zone}: PointsBoardProps) {
  return (
    <PointsBoardStyled className={zone.toLowerCase()}>
      <span className="points">{ points }</span>
    </PointsBoardStyled>
  );
}