import { useState, useEffect } from "react";
import { Resolution } from '../models';
import { getDisplayWidth } from '../helpers';

interface WindowSizeParams {
  width: number;
  height: number;
  resolution: Resolution;
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSizeParams | null>(null);
  useEffect(() => {
    function handleResize() {
    const resolution = getDisplayWidth(window.innerWidth)!;
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        resolution
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}