export enum GameStatus {
  PLAYING = 'PLAYING',
  STOPPED = 'STOPPED',
  PAUSED = 'PAUSED',
  LOST = 'LOST',
  WON = 'WON',
}

export enum Zone {
  ZONE_80s = 'ZONE_80s',
  ZONE_90s =  'ZONE_90s',
  ZONE_20s = 'ZONE_20s'
}

export interface ThemeItem {
  color: string;
  mainFont: string;
  numbersFont: string;
}

export interface BaseObj<T = string> {
  [key: string]: T;
}

export interface Coords {
  x: number;
  y: number;
}

export interface TimerProps {
  time: number;
  timeEndHandler: (state: boolean) => void;
  isPaused: boolean;
  zone: Zone;
}

export interface TetrisGameState {
  points: number;
  linesCleared: number;
}

export interface PointsBoardProps {
  points: number;
  zone: Zone;
}

export interface PredefinedLevelConfig {
  totalPoints: number;
  speed: number;
  time: number;
  board?: number[][];
  levelDescription: string | string[];
}

export interface Location {
  hash: string
  key: string
  pathname: string
  search: string
  state: any
}

export enum LevelStatus {
  LEVEL_COMPLETE = 'LEVEL_COMPLETE',
  GAME_OVER = 'GAME_OVER',
  GAME_COMPLETE = 'GAME_COMPLETE',
}

export interface LevelResult {
  score: number;
  bestScore: number | null;
  status: GameStatus;
}

export interface LevelResultProps {
  status: LevelStatus;
  level: number;
  close: () => void;
}

export interface LevelProgressZone80Props {
  points: number,
  totalPoints: number,
}

export interface GameModalProps {
  className: string;
  hide: () => void;
  text: string;
}

export interface BgZoneImagesProps {
  zone: Zone;
}

export interface ButtonConfig {
  text: string;
  handler: () => void;
  className: string;
}

export interface LevelMapItemProps {
  level: number;
  isMobile?: boolean;
  isClosed?: boolean;
  isCompleted?: boolean;
  open: (level: number) => void;
}

export interface BaseButtonProps {
  handler: () => void;
  children: React.ReactNode;
  className?: string;
}

export interface BaseImgProps {
  src: string;
  srcset?: string;
  alt?: string;
  className?: string;
}

export interface BaseProgressBarProps {
  value: number,
  isRounded: boolean,
  max?: number,
  className?: string
}

export interface ModalProps {
  visible: boolean;
  hide: () => void;
  children: React.ReactNode;
  className?: string;
  target?: HTMLElement | null;
}

export interface MuteButtonProps {
  callback: (isMute: boolean) => void;
  className?: string;
  zone: Zone;
}

export interface ZonedWrapperProps {
  zone: Zone;
  children: React.ReactNode;
}

export interface LevelInfoProps {
  zone: Zone;
}

export interface IconProgress90sProps {
  progress: number;
}

export interface TetrisProps {
  scoreCallback: (state: TetrisGameState) => void,
  gameStatusCallback: (state: GameStatus) => void,
  modalCallback: () => void;
  speed: number;
  text: string;
  board?: number[][];
  zone: Zone;
}

export interface IconColumnTopperProps {
  className: string;
}

export interface Bg90sColumnProps {
  position: string;
}

export interface NextBlockBoxProps {
  children: React.ReactNode;
  className?: string;
  zone: Zone;
}

export enum Resolution {
  DESKTOP_XL = 'DESKTOP_XL',
  DESKTOP_L = 'DESKTOP_L',
  DESKTOP_M = 'DESKTOP_M',
  DESKTOP_S = 'DESKTOP_S',
  LAPTOP = 'LAPTOP',
  TABLET = 'TABLET',
  MOBILE_L = 'MOBILE_L',
  MOBILE_M = 'MOBILE_M',
  MOBILE_S = 'MOBILE_S',
  MOBILE_XS = 'MOBILE_XS',
}